const guideItemListUs = [
  {
    header: "Human resources",
    message:
      "Sensitive and non-sensitive personal data, demographic, employment and pay-related data",
    pathName: "/guides/api-guides?useCaseTags=Human-Resources",
  },
  {
    header: "Time & labor management",
    message:
      "Time and attendance, workforce management,  timeclock punches, shift scheduling",
    pathName: "/guides/api-guides?useCaseTags=Time-Labor-Management",
  },
  {
    header: "Payroll management",
    message:
      "Data needed for payroll processing, such as regular hours and overtime hours, as well as processed data, such as earnings, deductions, taxes and more",
    pathName: "/guides/api-guides?useCaseTags=Payroll-Management",
  },
  {
    header: "Recruiting",
    message:
      "Data related to job requisitions, applications and assessments, background checks, hiring, onboarding",
    pathName: "/guides/api-guides?useCaseTags=Recruiting",
  },
  {
    header: "Benefits management",
    message:
      "Employee benefits, deductions, dependents, and insurance management",
    pathName: "/guides/api-guides?useCaseTags=Benefit-Management",
  },
];

const guideItemListInt = [
  {
    header: "API & Data Connectors",
    message:
      "Integrate ADP data with your HR and business solutions. Build solutions to synchronize employee records, payroll information, organizational structures, and more, in real time or through batch processing.",
    pathName: "/guides/api-guides?useCaseTags=API+%26+Data+Connectors",
  },
  {
    header: "Compensation",
    message:
      "Manage employee compensation, including salaries, bonuses, and profit sharing. Build solutions to streamline compensation planning, automate payroll adjustments, and help ensure compliance.",
    pathName: "/guides/api-guides?useCaseTags=Compensation",
  },
  {
    header: "Engagement & Benefits",
    message:
      "Build integrations to help engage employees and streamline benefits program administration and management.",
    pathName: "/guides/api-guides?useCaseTags=Engagement+%26+Benefits",
  },
  {
    header: "Finance",
    message:
      "Bridge HR and financial systems by ensuring a seamless integration for payroll accounting. Build solutions that offer financial flexibility through earned wage access, fleet management, and more.",
    pathName: "/guides/api-guides?useCaseTags=Finance",
  },
  {
    header: "Learning Management",
    message:
      "Support continuous learning and employee development, including course enrollment, training progress, and certification tracking. Build solutions to offer learning, track outcomes, and support compliance.",
    pathName: "/guides/api-guides?useCaseTags=Learning+Management",
  },
  {
    header: "Performance",
    message:
      "Help facilitate employee performance management including goal setting, performance evaluations, and feedback.",
    pathName: "/guides/api-guides?useCaseTags=Performance",
  },
  {
    header: "Productivity & Collaboration",
    message:
      "Integrate HR data with productivity and collaboration systems. Build solutions to automate workflows, simplify administrative tasks, streamline document management, and improve team communication.",
    pathName: "/guides/api-guides?useCaseTags=Productivity+%26+Collaboration",
  },
  {
    header: "Recruitment & Onboarding",
    message:
      "Simplify the hiring process, from job posting to onboarding new hires. Build solutions to automate recruitment workflows, track candidate progress, and provide smooth onboarding experiences.",
    pathName: "/guides/api-guides?useCaseTags=Recruitment+%26+Onboarding",
  },
  {
    header: "Time & Activites Management",
    message:
      "Help track employee time and attendance, manage leave and absence, and more for accurate timekeeping and payroll processing. Build time data integrations between ADP and other systems.",
    pathName: "/guides/api-guides?useCaseTags=Time+%26+Activities+Management",
  },
  {
    header: "Travel & Expense Management",
    message:
      "Build integrations between ADP and other systems to simplify expense submission and approval, automate reimbursements, and integrate with payroll.",
    pathName: "/guides/api-guides?useCaseTags=Travel+%26+Expense+Management",
  },
];

export { guideItemListUs, guideItemListInt };