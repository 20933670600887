import "./GoLiveReadiness.css";

export const GoLiveReadiness = ({ readinessValues }) => {
    return (
      <div className="bg-action-default-100 rounded-lg p-2 font-normal max-w-4xl mx-auto pb-4">
        <p className="text-center p-0 m-0 text-heading-02">Go-live readiness</p>
        <ul className="pl-6 grid grid-cols-2 gap-x-4 gap-y-2 text-left font-normal readiness-gap">
          {readinessValues.map((values, idx) => (
            <li className="break-words list-disc" key={idx}>
              {values}
            </li>
          ))}
        </ul>
      </div>
    );
  };