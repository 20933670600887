import { useEffect, useRef, useState } from "react";
import { SdfLink } from "@synerg/react-components";

import imageTechnology from "../../assets/images/technology.svg";
import "./IntroducingApiCentral.css";

const IntroducingApiCentral = () => {
  const sizeRef = useRef(null);
  const [imageSize, setImageSize] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setImageSize(Math.min(entry.contentRect.height, entry.contentRect.width));
    });

    if (sizeRef.current) {
      resizeObserver.observe(sizeRef.current);
    }

    return () => {
      if (sizeRef.current) {
        resizeObserver.unobserve(sizeRef.current);
      }
    };
  }, [sizeRef.current]);

  return (
    <div className="bg-action-primary-900 rounded-lg w-11/12 md:w-4/5">
      <div className="flex flex-col lg:flex-row px-8 py-12 gap-16">
        <div className="flex-grow introducing-text-container" ref={sizeRef}>
          <h2 className="m-0 text-heading-07 text-inverse-default pb-4">
            Introducing ADP® API Central for clients
          </h2>
          <p className="m-0 text-body-02 text-inverse-default pb-6">
            Contact us to find out how you can get started working with APIs
            available for international ADP products.
          </p>
          <SdfLink
            emphasis="primary"
            href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL5N1d5hK5v1MsggoA49vwMZUOVhWVlpWTE9HRUFYRTNGMjg4NzJOS1NUVS4u"
            target="_blank"
          >
            Contact us to learn more
          </SdfLink>
        </div>
        <div
          className="relative flex-shrink-0 introducing-image-container mx-auto"
          style={{ "--introducingImageSize": `${imageSize}px` }}
        >
          <div className="bg-action-help-700 w-full h-full introducing-image-shape" />
          <img
            src={imageTechnology}
            alt="Technology"
            className="absolute m-auto introducing-image"
          />
        </div>
      </div>
    </div>
  );
};

export default IntroducingApiCentral;
