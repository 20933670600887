import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Breadcrumb from "../../../components/Breadcrumb";
import { useFetchArticleQuery } from "../../../store";
import { SdfIcon, SdfSelectSimple } from "@synerg/react-components";
import { getBreadcrumb } from "../../../utility/articlesUtils";
import { ContentsMenu } from "../../../components/articles/ContentsMenu";
import { PageTitle } from "../../../components/PageTitle";
import { ChapterHtml } from "../../../components/articles/chapterHtml/ChapterHtml";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { NavChapter } from "../../../components/articles/NavChapter";
import { BusyPageIndicator } from "../../../components/BusyPageIndicator";
import { useLocation } from "react-router";

function ViewGuideChapterPage() {
  const { type, guideId, chapterId } = useParams();
  const navigate = useNavigate();
  const [prevChapter, setPrevChapter] = useState();
  const [nextChapter, setNextChapter] = useState();
  const [breadcrumb, setBreadcrumb] = useState("");
  const currentChapter = Number(chapterId);
  const currentChapterIndex = Number(chapterId) - 1;
  const isLgOrBigger = useMediaQuery("(min-width: 1024px)");
  const location = useLocation();
  const currentPath = location.pathname;

  const { isLoggedIn } = useSelector((state) => {
    const { isLoggedIn } = state.auth;
    return {
      isLoggedIn,
    };
  });

  const {
    isLoading,
    isSuccess,
    data: guide,
  } = useFetchArticleQuery({ index: "guides", id: guideId });

  useEffect(() => {
    if (
      currentPath.startsWith("/getting-started/getting-started-as-a-client")
    ) {
      setBreadcrumb("getting-started-client");
    } else if (
      currentPath.startsWith("/getting-started/getting-started-as-a-partner")
    ) {
      setBreadcrumb("getting-started-partner");
    } else {
      setBreadcrumb(type);
    }
  }, [currentPath]);

  const breadcrumbPath = getBreadcrumb(
    breadcrumb,
    guide?.chaptersSections?.[currentChapterIndex]?.title,
    [guideId, guide?.title]
  );

  const chapterList = guide?.chapters?.map((chapter) => {
    return {
      label: `Ch. ${chapter.step}: ${chapter.title}`,
      value: chapter.step,
    };
  });

  const mapChapter = () => {
    const calcPrev =
      currentChapter === 1
        ? ""
        : guide?.chaptersSections?.[currentChapterIndex - 1]?.title;
    const lastChapter = guide?.chaptersSections?.length;
    const calcNext =
      currentChapter === lastChapter
        ? ""
        : guide?.chaptersSections?.[currentChapter]?.title;
    setPrevChapter(calcPrev);
    setNextChapter(calcNext);
  };

  useEffect(() => {
    mapChapter();
  }, [currentChapter, isSuccess]);

  const getGuidePath = (chapter) => {
    return `${window.location.pathname.replace(
      /chapter\/.+$/g,
      ""
    )}chapter/${chapter}`;
  };

  const onChangeChapter = (event) => {
    const chapter = event.detail.value;
    mapChapter(chapter);
    navigate(getGuidePath(chapter));
  };

  const handlePrev = () => {
    if (currentChapter !== 1) {
      const prevChapter = currentChapter - 1;
      mapChapter(prevChapter);
      navigate(getGuidePath(prevChapter));
    }
  };

  const handleNext = () => {
    const lastChapter = guide?.chaptersSections?.length;
    if (currentChapter !== lastChapter) {
      const nextChapter = currentChapter + 1;
      mapChapter(nextChapter);
      navigate(getGuidePath(nextChapter));
    }
  };

  return isLoading ? (
    <BusyPageIndicator positionClass="absolute-center" />
  ) : (
    <>
      <div className="pl-12 relative pr-1 top-0">
        <Breadcrumb path={breadcrumbPath} />
        {guide.isPublic || isLoggedIn ? (
          <>
            <div className="flex flex-col md:flex-row lg:flex-row md:items-center md:justify-between lg:items-center lg:justify-between mt-6">
              <PageTitle text={guide.title} className="text-heading-07" />
              {(guide.isPublic || isLoggedIn) && (
                <SdfSelectSimple
                  className="pr-10"
                  label="Jump to chapter"
                  inputWidth_md="md"
                  items={chapterList}
                  value={chapterList?.[currentChapterIndex]}
                  onSdfChange={onChangeChapter}
                />
              )}
            </div>
            <div className="flex justify-between pt-4 md:pt-10 md:w-10/12">
              <div className="text-tertiary font-medium">
                CHAPTER {currentChapter}
              </div>
              <a
                href={guide?.chaptersSections?.[currentChapterIndex].pdfLink}
                target="_blank"
                className="cursor-pointer no-underline hover:underline text-action-primary mr-16"
                rel="noreferrer"
              >
                <SdfIcon icon="action-download" />
                Download chapter
              </a>
            </div>
            <div className="flex">
              <div className="mt-4 w-11/12 overflow-x-hidden">
                <div className="text-heading-06 font-bold pt-2">
                  {guide?.chaptersSections?.[currentChapterIndex].title}
                </div>
                <ChapterHtml
                  htmlStr={
                    guide?.chaptersSections?.[currentChapterIndex].content
                  }
                />
              </div>
              <ContentsMenu
                article={guide}
                isLgOrBigger={isLgOrBigger}
                className="-mt-5"
                isGuide
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex pt-8 flex-col justify-center items-center">
              <SdfIcon icon="status-locked" external />
              <div className="pt-4">Please log in to view content</div>
            </div>
          </>
        )}
        <div className="flex justify-between lg:w-10/12 h-12 mt-12 mb-4">
          <NavChapter isPrev chapterTitle={prevChapter} onClick={handlePrev} />
          <NavChapter chapterTitle={nextChapter} onClick={handleNext} />
        </div>
      </div>
    </>
  );
}

export default ViewGuideChapterPage;
