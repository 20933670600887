import { useState } from "react";
import {
  SdfPromoBox,
  SdfBadge,
  SdfAlert,
  SdfBox,
  SdfIcon,
  SdfButton,
} from "@synerg/react-components";

import AssociatedApis from "./AssociatedApis";
import {
  startItemListUs,
  needItemListUs,
  startItemListInt,
  needItemListInt,
} from "./getStartedItemList";
import "./GetStarted.css";
import { Link } from "react-router-dom";

const getStartedId = "get-started";

const StartBox = ({ onClick, pathName, type, className, ...rest }) => {
  const boxProps = {
    className: `flex-grow ${className ? ` ${className}` : ""}`,
    size: "sm",
    variant: "clear",
  };

  const buttonProps = {
    onClick,
    slot: "cta",
    variant: "text",
    className: "px-4 pb-4",
  };
  return (
    <div className="relative col-span-2 xl:col-span-1 border-solid border-2 rounded-lg start-box-border">
      <div className="w-full h-full flex flex-col">
        <SdfPromoBox {...boxProps} {...rest} />
        <SdfButton {...buttonProps}>View associated APIs</SdfButton>
      </div>
      {type && (
        <SdfBadge
          className={`absolute top-0 right-0 pt-4 pr-4`}
          status={type === "read" ? "in-progress" : "changed"}
        >
          {type === "read" ? "Read only" : "Read/Write"}
        </SdfBadge>
      )}
    </div>
  );
};

const NeedBox = ({ header, message, illustrationName, className, ...rest }) => {
  const boxProps = {
    className: `w-full h-full${className ? ` ${className}` : ""}`,
    variant: "bordered",
  };

  return (
    <li className="relative col-span-2 xl:col-span-1">
      <SdfBox {...boxProps} {...rest}>
        <div className="relative inline-block need-box-icon-container">
          <div className="absolute bg-alert-info-50 w-full h-full need-box-icon-shape" />
          <SdfIcon
            icon={illustrationName}
            className="text-3xl text-status-progress m-auto w-full h-full"
          />
        </div>
        <div className="m-0 py-2 text-heading-04">{header}</div>
        <div className="m-0 text-body-02 text-tertiary">{message}</div>
      </SdfBox>
    </li>
  );
};

/**
 * @param {{ international: boolean }} props
 */
const GetStarted = ({ international }) => {
  const [apiData, setApiData] = useState(null);

  const associatedApisProps = {
    closeModal: () => setApiData(null),
    apiData,
    international,
  };

  const startItemList = international ? startItemListInt : startItemListUs;
  const needItemList = international ? needItemListInt : needItemListUs;

  return (
    <>
      <div className="w-11/12 md:w-4/5" id={getStartedId}>
        <div className="flex flex-col items-center gap-4">
          <h2 className="m-0 text-heading-06">
            {international
              ? "Get started with API integration use cases"
              : "Get started with API use case templates"}
          </h2>
          <p className="m-0 text-body-02 text-center max-w-6xl">
            {international
              ? "When starting a new custom integration project, developers can choose from a set of common API use cases with a list of relevant APIs to begin development quickly."
              : "When starting a new custom integration project, developers can select from a set of common API use case templates, pre-populated with relevant APIs, to begin development quickly. Use the API discovery tool to add more APIs as you need them."}
          </p>
          <ul className="list-none m-0 pl-0 w-full pt-12 grid grid-cols-4 auto-rows-fr gap-4">
            {startItemList.map((item, index) => (
              <StartBox
                key={`started-box-${index}`}
                onClick={() => setApiData(item)}
                {...item}
              />
            ))}
          </ul>
          <div className="m-0 text-heading-04">
            {international
              ? "…plus more integration use cases the APIs can unlock!"
              : "…plus add even more APIs to your starter project with the API discovery tool!"}
          </div>
          <SdfAlert icon="alert-notification" size="md" className="pt-4 w-full">
            <span slot="title">Sample templates are representative</span>
            {international ? (
              <span>
                Pre-populated use cases vary by ADP product, are defined based
                on synergies, and represent a centralized set of associated APIs
                that enable integrations between different systems.
              </span>
            ) : (
              <span>
                Pre-populated use cases vary by ADP product. The above examples
                and associated APIs are taken from one of ADP’s most popular
                products,{" "}
                <Link
                  to={
                    process.env.PUBLIC_URL + "/apis/api-explorer/hcm-offrg-wfn"
                  }
                >
                  ADP Workforce Now®.
                </Link>
              </span>
            )}
          </SdfAlert>
          <h3 className="m-0 pt-12 text-heading-06">
            Everything you need, all in one place
          </h3>
          <ul className="list-none m-0 pl-0 w-full pt-4 pb-8 grid grid-cols-4 auto-rows-fr gap-4">
            {needItemList.map((item, index) => (
              <NeedBox key={`started-box-${index}`} {...item} />
            ))}
          </ul>
        </div>
      </div>
      <AssociatedApis {...associatedApisProps} />
    </>
  );
};

export default GetStarted;
export { getStartedId };
