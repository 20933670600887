import { useEffect, useState } from "react";
import { SdfSegmentedControl } from "@synerg/react-components";

import HeroImage from "../shared/HeroImage";
import { HeroImageList } from "../shared/HeroImageList";
import imageUs from "../../assets/images/hero_desktop.png";
import imageInt from "../../assets/images/hero_desktop_int.jpeg";
import "./Header.css";

const imagePropsUs = {
  src: imageUs,
  alt: "A woman smiling as she sits at her desk and views her laptop",
  partnerLanding: false,
  linkList: [
    {
      title: "Recruiting",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=staffing",
      className: "hero-image-recruiting",
      ariaLabel: "View APIs related to recruiting",
    },
    {
      title: "Benefits",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=benefits",
      className: "hero-image-benefits",
      ariaLabel: "View APIs related to benefits",
    },
    {
      title: "Onboarding",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=hcm",
      className: "hero-image-onboarding",
      ariaLabel: "View APIs related to onboarding",
    },
    {
      title: "Payroll",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=payroll",
      className: "hero-image-payroll",
      ariaLabel: "View APIs related to payroll",
    },
    {
      title: "Employee data",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=hr",
      className: "hero-image-data",
      ariaLabel: "View APIs related to employee data",
    },
    {
      title: "Time & attendance",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=time",
      className: "hero-image-time",
      ariaLabel: "View APIs related to time and attendance",
    },
    {
      title: "Employee qualifications",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=talent",
      className: "hero-image-qualifications",
      ariaLabel: "View APIs related to employee qualifications",
    },
  ],
};

const imagePropsInt = {
  src: imageInt,
  alt: "A woman sits at her desk and takes notes while listening to a call",
  partnerLanding: false,
  linkList: [
    {
      title: "Benefits",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge?domain=benefits",
      className: "hero-image-benefits-int",
      ariaLabel: "View APIs related to benefits",
    },
    {
      title: "Core",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge?domain=core",
      className: "hero-image-core-int",
      ariaLabel: "View APIs related to core",
    },
    {
      title: "HCM",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge?domain=hcm",
      className: "hero-image-hcm-int",
      ariaLabel: "View APIs related to HCM",
    },
    {
      title: "HR",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge?domain=hr",
      className: "hero-image-hr-int",
      ariaLabel: "View APIs related to HR",
    },
    {
      title: "Payroll",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge?domain=payroll",
      className: "hero-image-payroll-int",
      ariaLabel: "View APIs related to payroll",
    },
    {
      title: "Time",
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge?domain=time",
      className: "hero-image-time-int",
      ariaLabel: "View APIs related to time",
    },
  ],
};

const controlProps = [
  { label: "United States", value: "us" },
  { label: "International", value: "int" },
];

const headerDataList = [
  {
    title: "Integrate your HR and business solutions with ADP APIs",
    description:
      "ADP® API Central provides instant access to APIs, tools and services, all in one place.",
    imageProps: imagePropsUs,
  },
  {
    title: "Global APIs for every stage within the worker life cycle",
    description:
      "From hire to retire, ADP offers global APIs based on your business size and area of interest for your next data integration.",
    imageProps: imagePropsInt,
  },
];

/**
 *
 * @param {{
 *  international: boolean
 *  setInternational: (value?: boolean) => void
 * }} props
 */
const Header = ({ international, setInternational }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const index = international ? 1 : 0;
  const headerData = headerDataList[index];

  return (
    <div className="w-11/12 md:w-4/5 pb-12">
      <div className="flex justify-center pt-6 pb-12">
        <SdfSegmentedControl
          items={controlProps}
          selectedIndex={index}
          onSdfChange={(event) =>
            setInternational(event.detail === 1 ? true : false)
          }
        />
      </div>
      <div className="flex flex-col xl:flex-row">
        <div className="xl:mr-16 my-auto">
          <h1 className="m-0 pb-8 text-heading-08">{headerData.title}</h1>
          <p className="m-0 text-heading-04">{headerData.description}</p>
        </div>
        {windowWidth >= 640 ? (
          <div className="flex-shrink-0 h-xs w-full pt-16 xl:w-xl xl:py-0">
            <HeroImage {...headerData.imageProps} />
          </div>
        ) : (
          <div className="pt-16">
            <HeroImageList {...headerData.imageProps} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
