/**
 * @typedef {Object.<string, string>} Product
 * @param {Product} a
 * @param {Product} b
 * @param {string} [key]
 */
const sortAlphabeticalNoSymbol = (a, b, key = "title") =>
  (a[key] || "")
    .replace(/\W/g, "")
    .toLocaleLowerCase()
    .localeCompare((b[key] || "").replace(/\W/g, "").toLocaleLowerCase());

export default sortAlphabeticalNoSymbol;
