const commonApis = [
  {
    verb: "POST",
    path: "/auth/oauth/v2/token",
    name: "ADP Access Token",
    canonical: "",
    text: "Access Token",
  },
];

const startItemListUs = [
  {
    header: "Employee Demographic Data",
    message:
      "Read all employee demographic data and related validation tables. Sensitive personal information is hidden by default and can be allowed in the application.",
    illustrationName: "people-1",
    type: "read",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/business-units",
        name: "List of items in validation table - Business Units",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/businessUnits.items.read",
        text: "List of tems in the validation table - Business Units",
        type: "table",
        domain: "hcm",
        subCategory: "Business Units",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/cost-centers",
        name: "List of items in validation table - Cost Centers",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/costCenters.items.read",
        text: "List of tems in the validation table - Cost Centers",
        type: "table",
        domain: "hcm",
        subCategory: "Cost Centers",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/departments",
        name: "List of items in validation table - Departments",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/departments.items.read",
        text: "List of tems in the validation table - Departments",
        type: "table",
        domain: "hcm",
        subCategory: "Departments",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/meta",
        name: "Returns a meta for Jobs, Pass method parameter to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.meta.read",
        text: "Returns the meta for the Jobs validation tables",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/{item-id}",
        name: "Details of a specific record in the validation table - Jobs",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.read",
        text: "Specific record in the validation table - Jobs",
        type: "table",
        domain: "hcm",
        subCategory: "Jobs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/meta",
        name: "Returns a meta for validation tables, Pass method and type parameters in order to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/validationTable.meta.read",
        text: "Returns the meta for validation tables based on parameters",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts",
        name: "Request the list of corporate directory entries.",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Directory",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts/{contact-id}",
        name: "Request the details of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/{sor-id}/hr/v1/corporate-contact-photos/{image-id}.{image-extension}",
        name: "Request an image of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact Image",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: false,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.add",
        name: "A worker's additional remuneration (e.g. bonus, commission, etc.) is added.",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.add",
        text: "Add Additional Remuneration",
        type: "regular",
        domain: "hr",
        subCategory: "Add Additional Remuneration",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.add/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.add",
        text: "Add Additional Remuneration Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Add Additional Remuneration",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/worker-images/photo",
        name: "Returns a worker profile picture",
        canonical:
          "/hr/workerInformationManagement/workerManagement/imageManagement/worker.photo.read",
        text: "Worker Profile Picture",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Request the list of all available worker demographics that the requester is authorized to view.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographics",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Returns a worker demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
    ],
  },
  {
    header: "Time & Attendance",
    message: "Read and update timecards and work schedules.",
    illustrationName: "time-clock",
    type: "read",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/cost-centers",
        name: "List of items in validation table - Cost Centers",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/costCenters.items.read",
        text: "List of tems in the validation table - Cost Centers",
        type: "table",
        domain: "hcm",
        subCategory: "Cost Centers",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/departments",
        name: "List of items in validation table - Departments",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/departments.items.read",
        text: "List of tems in the validation table - Departments",
        type: "table",
        domain: "hcm",
        subCategory: "Departments",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/meta",
        name: "Returns a meta for Jobs, Pass method parameter to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.meta.read",
        text: "Returns the meta for the Jobs validation tables",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/{item-id}",
        name: "Details of a specific record in the validation table - Jobs",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.read",
        text: "Specific record in the validation table - Jobs",
        type: "table",
        domain: "hcm",
        subCategory: "Jobs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/meta",
        name: "Returns a meta for validation tables, Pass method and type parameters in order to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/validationTable.meta.read",
        text: "Returns the meta for validation tables based on parameters",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.read",
        name: "A worker reads.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.read/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Request the list of all available workers that the requester is authorized to view",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Workers Data Collection",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Returns a worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Individual Worker Data",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/meta",
        name: "Returns a worker specific meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Specific Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/staticContent/{sor-id}/hr/associates/{aoid}/photos/{photo-id}",
        name: "Request a photo",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Photo (Legacy)",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images (Deprecated)",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Request the list of all available worker demographics that the requester is authorized to view.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographics",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Returns a worker demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/team-time-cards",
        name: "Get a worker's team's timecards.  That is all the time cards for the worker's team members.  The worker (team lead / manager) is identified by workers/{aoid}",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Team Time Cards",
        type: "regular",
        domain: "time",
        subCategory: "Team Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/team-time-cards/meta",
        name: "Returns API metadata. NOTE: The {aoid} URI parameter refers to the team lead / manager, whose team data is being requested.",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Team Time Cards Meta",
        type: "meta",
        domain: "time",
        subCategory: "Team Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-cards",
        name: "Get a workers time card for a given period.  Filter criteria is required.",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Cards",
        type: "regular",
        domain: "time",
        subCategory: "Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-cards/{time-card-id}",
        name: "Get a workers time card for a given period.  Filter criteria is required.",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Card",
        type: "regular",
        domain: "time",
        subCategory: "Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-cards/meta",
        name: "Returns API metadata",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Cards Meta",
        type: "meta",
        domain: "time",
        subCategory: "Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v1/workers/{aoid}/time-entry-details",
        name: "Request a time entry configuration",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Entry Configuration",
        type: "regular",
        domain: "time",
        subCategory: "Time Entry Details",
        globalDevPortalFlag: false,
      },
    ],
  },
  {
    header: "New Hire Onboarding",
    message:
      "Send new hires, onboard completed hires, and read and rehire existing employees to ADP.",
    illustrationName: "teacher",
    type: "write",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/hcm/v2/applicant.onboard",
        name: "Data related to the applicant new hire onboarding request. A new hire is an applicant who has not previously worked for the organization.",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicant.onboard",
        text: "Initiate Applicant Onboarding (New Hire Applicants)",
        type: "regular",
        domain: "hcm",
        subCategory: "New Hire Onboarding Process Management",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hcm/v2/applicant.onboard/meta",
        name: "Returns metadata for the applicant new hire onboarding API",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicant.onboard.meta.read",
        text: "Metadata - Initiate Applicant Onboarding API (New Hire Applicants)",
        type: "meta",
        domain: "hcm",
        subCategory: "New Hire Onboarding API Metadata",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts",
        name: "Request the list of corporate directory entries.",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Directory",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts/{contact-id}",
        name: "Request the details of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/{sor-id}/hr/v1/corporate-contact-photos/{image-id}.{image-extension}",
        name: "Request an image of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact Image",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/worker-images/photo",
        name: "Returns a worker profile picture",
        canonical:
          "/hr/workerInformationManagement/workerManagement/imageManagement/worker.photo.read",
        text: "Worker Profile Picture",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.read",
        name: "A worker reads.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.read/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.rehire",
        name: "A worker is rehired.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Rehire Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Rehire Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.rehire/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Rehire Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Rehire Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/hr/v3/lifecycle-management/workers/{aoid}/rehire",
        name: "Perform the rehire action for a given worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Rehire Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Rehire",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Request the list of all available workers that the requester is authorized to view",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Workers Data Collection",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Returns a worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Individual Worker Data",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/meta",
        name: "Returns a worker specific meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Specific Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/staticContent/{sor-id}/hr/associates/{aoid}/photos/{photo-id}",
        name: "Request a photo",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Photo (Legacy)",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images (Deprecated)",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Request the list of all available worker demographics that the requester is authorized to view.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographics",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Returns a worker demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelist",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterprise/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterpriseportal/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/vantage/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/worker.pay-distribution.change",
        name: "A worker changes how the pay is distributed for a given payroll agreement",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.change",
        text: "Change Pay Distribution",
        type: "regular",
        domain: "payroll",
        subCategory: "Change Pay Distribution",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/worker.pay-distribution.change/meta",
        name: "Returns an event metadata",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.change",
        text: "Change Pay Distribution Meta",
        type: "meta",
        domain: "payroll",
        subCategory: "Change Pay Distribution",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions",
        name: "Request all available pay distributions for a worker",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Pay Distributions",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Distributions",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions/{pay-distribution-id}",
        name: "Returns a pay distribution",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Pay Distribution",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Distributions",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions/meta",
        name: "Returns a meta",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Pay Distribution Meta",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Distributions",
        globalDevPortalFlag: true,
      },
    ],
  },
  {
    header: "Payroll Input",
    message:
      "Add pay-data batches for a payroll cycle to include earnings, deductions, reimbursements and more.",
    illustrationName: "money-hand",
    type: "write",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "worker.legalName.change.eventNotify",
        name: "Communicates the occurrence of an event",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.legalName.change.eventNotify.subscribe",
        text: "Change Legal Name Notification",
        type: "regular",
        domain: "hr",
        subCategory: "Change Legal Name",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.read",
        name: "A worker reads.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.read/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Request the list of all available workers that the requester is authorized to view",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Workers Data Collection",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Returns a worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Individual Worker Data",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/meta",
        name: "Returns a worker specific meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Specific Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/staticContent/{sor-id}/hr/associates/{aoid}/photos/{photo-id}",
        name: "Request a photo",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Photo (Legacy)",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images (Deprecated)",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-method-type-codes/autopay",
        name: "Request the list of all method type codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Tax Filing Statuses Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-process-frequency-codes/autopay",
        name: "Request the list of all process frequency codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Retrieve Process Frequency Codes",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-bag-type-codes/autopay",
        name: "Request the list of all bag type codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-special-handling-codes/autopay",
        name: "Request the list of all special handling codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Staffing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-trip-center-codes/autopay",
        name: "Request the list of all trip center codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Retrieve Process Frequency Codes",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-output-type-codes/autopay",
        name: "Request the list of all output type codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-output-frequency-codes/autopay",
        name: "Request the list of all output frequency codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Staffing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-maintenance-quarters/autopay",
        name: "Request the list of all a company's maintenance quarter codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/payroll-processing-error-codes/autopay",
        name: "Request the list of all payroll errors and descriptions that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Processing Jobs Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/cafeteria-plan-tax-options/autopay",
        name: "Request the list of all available Cafeteria Plan Tax Options that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Cafeteria Plan Tax Options",
        type: "codelist",
        domain: "payroll",
        subCategory: "Cafeteria Plan Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/cafeteria-plan-benefit-report-type-codes/autopay",
        name: "Request the list of all available Cafeteria Plan Benefit Verification Report Options that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Cafeteria Plan Benefit Verification Report Options.",
        type: "codelist",
        domain: "payroll",
        subCategory: "Cafeteria Plan Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-configuration/{code-type}/autopay",
        name: "Request list of all available companies which has active block print automation indicator",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Retrieve list of active block print automation companies",
        type: "codelist",
        domain: "payroll",
        subCategory: "Pay Statement Attribute Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-configurations/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollConfigurations/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Configuration Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelist",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterprise/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterpriseportal/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/vantage/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-processing/{processing-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-processing/{processing-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-processing/{processing-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-processing/{processing-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.modify",
        name: "Modify pay data input information",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify Pay Data Input",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.modify/meta",
        name: "Returns an event metadata",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify Pay Data Input Meta",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
        globalDevPortalFlag: true,
      },
    ],
  },
];

const startItemListInt = [
  {
    header: "Employee Worker Sync",
    message:
      "Read all employee demographic data and allow ADP workers sync to any system.",
    illustrationName: "people-1",
    apis: [
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Worker Demographics",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Request the list of all available worker demographics that the requester is authorized to view.",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Worker Demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Returns a worker demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Worker Demographic Meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Returns a meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
    ],
  },
  {
    header: "Time & Attendance",
    message:
      "Centered around punching and tracking system and attendances. Import employee data from ADP and send pay data to ADP.",
    illustrationName: "time-clock",
    apis: [
      {
        verb: "GET",
        path: "/core/v1/organization-departments",
        name: "Organization Departments",
        canonical:
          "/core/organizationManagement/organizationInformationManagement/organizationDepartmentManagement/organizationDepartment.read",
        text: "Returns organization departments details",
        type: "regular",
        domain: "core",
        subCategory: "Organization Departments",
      },
      {
        verb: "GET",
        path: "/core/v1/organization-departments/{department-id}",
        name: "Organization Department Instance",
        canonical:
          "/core/organizationManagement/organizationInformationManagement/organizationDepartmentManagement/organizationDepartment.read",
        text: "Returns single organization department detail",
        type: "regular",
        domain: "core",
        subCategory: "Organization Departments",
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Worker Demographics",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Request the list of all available worker demographics that the requester is authorized to view.",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Worker Demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Returns a worker demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.worker-original-hire-date.change/{event-id}",
        name: "Change Worker Original Hire Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/originalHireDateManagement/worker.workerOriginalHireDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Original Hire Date",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.worker-rehire-date.change/{event-id}",
        name: "Change Worker Rehire Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/rehireDateManagement/worker.workerRehireDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Rehire Date",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.worker-termination-date.change/{event-id}",
        name: "Change Worker Termination Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/terminationDateManagement/worker.workerTerminationDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Termination Date",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.add",
        name: "Add Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.add",
        text: "Add pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Add",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.add/meta",
        name: "Add Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.add",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Add",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.replace",
        name: "Replace Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.replace",
        text: "Replace pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Replace",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.replace/meta",
        name: "Replace Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.replace",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Replace",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.modify",
        name: "Modify Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.modify/meta",
        name: "Modify Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.hire/{event-id}",
        name: "Hire Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.hire.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Hire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.rehire/{event-id}",
        name: "Rehire Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Rehire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.terminate/{event-id}",
        name: "Terminate Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.terminate.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker-termination.cancel/{event-id}",
        name: "Cancel Worker Termination Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-termination.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Termination",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker-hire.cancel/{event-id}",
        name: "Cancel Worker Hire Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-hire.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Hire",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker-rehire.cancel/{event-id}",
        name: "Cancel Worker Rehire Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-rehire.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Rehire",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.add/{event-id}",
        name: "Add Work Assignment Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Work Assignment",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.terminate/{event-id}",
        name: "Terminate Work Assignment Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.terminate.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Work Assignment",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.modify/{event-id}",
        name: "Modify Work Assignment Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.modify.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Modify Work Assignment",
      },
      {
        verb: "GET",
        path: "/time/v1/workers/{aoid}/work-schedules",
        name: "Work Schedules",
        canonical:
          "/time/timeLaborManagement/scheduleManagement/workScheduleManagement/workSchedule.read",
        text: "Request the list of all available work schedules that the requester is authorized to view",
        type: "regular",
        domain: "time",
        subCategory: "Work Schedules",
      },
      {
        verb: "GET",
        path: "/time/v3/workers/{aoid}/time-off-requests",
        name: "Worker Time Off Requests",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.read",
        text: "Request the list of all available worker time off requests that the requester is authorized to view",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Requests",
      },
      {
        verb: "GET",
        path: "/time/v3/workers/{aoid}/time-off-requests/{time-off-request-id}",
        name: "Worker Time Off Request",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.read",
        text: "Returns a single time off request",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Requests",
      },
      {
        verb: "GET",
        path: "/time/v3/workers/{aoid}/time-off-balances",
        name: "Worker Time Off Balances",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffBalancesManagement/timeOffBalance.read",
        text: "Balance the list of all available worker time off balances that the requester is authorized to view",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Balances",
      },
      {
        verb: "POST",
        path: "/events/time/v3/time-off-balances.modify",
        name: "Modify Time Off Balances",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffBalancesManagement/timeOffBalances.modify",
        text: "Modify time off balances event instance",
        type: "regular",
        domain: "time",
        subCategory: "Modify Time Off Balances",
      },
      {
        verb: "POST",
        path: "/events/time/v1/time-off-request.submit",
        name: "Request Time Off",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.submit",
        text: "Request time off event instance",
        type: "regular",
        domain: "time",
        subCategory: "Request Time Off",
      },
      {
        verb: "GET",
        path: "/events/time/v1/time-off-request.submit/{event-id}",
        name: "Request Time Off Event Instance (by Event Manager)",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.submit.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "time",
        subCategory: "Request Time Off",
      },
      {
        verb: "POST",
        path: "/events/time/v1/time-off-request.cancel",
        name: "Cancel Time Off Request",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.cancel",
        text: "Cancel a time off request event",
        type: "regular",
        domain: "time",
        subCategory: "Cancel Time Off Request",
      },
      {
        verb: "GET",
        path: "/events/time/v1/time-off-request.cancel/{event-id}",
        name: "Cancel Time Off Request Event Instance (by Event Manager)",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "time",
        subCategory: "Cancel Time Off Request",
      },
      {
        verb: "POST",
        path: "/events/time/v1/time-off-request.change",
        name: "Change Time Off Request",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.change",
        text: "Change a time off request event instance",
        type: "regular",
        domain: "time",
        subCategory: "Change Time Off Request",
      },
      {
        verb: "GET",
        path: "/events/time/v1/time-off-request.change/{event-id}",
        name: "Change Time Off Request  Event Instance (by Event Manager)",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "time",
        subCategory: "Change Time Off Request",
      },
      {
        verb: "GET",
        path: "/time/v2/worker-time-off-entitlements",
        name: "Worker Time Off Entitlements",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffEntitlementManagement/timeOffEntitlement.read",
        text: "Request the list of all worker's time off entitlemnents that the requester is authorized to view",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Entitlements",
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-off-entitlements",
        name: "Worker Time Off Entitlements",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffEntitlementManagement/timeOffEntitlement.read",
        text: "Retrieve the worker time off entitlements for a specified worker",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Entitlements",
      },
      {
        verb: "GET",
        path: "/core/v2/organizational-units",
        name: "List Organizational Units for a Given OOID",
        canonical:
          "/core/organizationManagement/organizationInformationManagement/organizationUnitManagement/organizationalUnits.read",
        text: "Retrieve a list of all available organizational units",
        type: "regular",
        domain: "core",
        subCategory: "Organizational Units",
      },
      {
        verb: "GET",
        path: "/core/v2/organizational-units/{unit-id}",
        name: "Read Organizational Unit Details",
        canonical:
          "/core/organizationManagement/organizationInformationManagement/organizationUnitManagement/organizationalUnit.read",
        text: "Retrieves a single organizational unit based on the unit ID",
        type: "regular",
        domain: "core",
        subCategory: "Organizational Units",
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/work-schedules",
        name: "Work Schedules",
        canonical:
          "/time/timeLaborManagement/scheduleManagement/workScheduleManagement/workSchedules.read",
        text: "Request the list of all available work schedules for worker",
        type: "regular",
        domain: "time",
        subCategory: "Work Schedules",
      },
    ],
  },
  {
    header: "External HR",
    message:
      "Centralize HR management across locations, providing data for payroll processing, self-service portals and analytics.",
    illustrationName: "globe-markers",
    apis: [
      {
        verb: "GET",
        path: "/hr/v1/associates/{aoid}/dependents",
        name: "Dependents",
        canonical:
          "/hr/workerInformationManagement/dependentInformationManagement/dependentManagement/dependent.read",
        text: "Request the list of all available dependents that the requester is authorized to view",
        type: "regular",
        domain: "hr",
        subCategory: "Dependents",
      },
      {
        verb: "GET",
        path: "/hr/v1/associates/{aoid}/dependents/{dependent-id}",
        name: "Dependent",
        canonical:
          "/hr/workerInformationManagement/dependentInformationManagement/dependentManagement/dependent.read",
        text: "Returns a dependent",
        type: "regular",
        domain: "hr",
        subCategory: "Dependents",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/dependent.add",
        name: "Add Dependent",
        canonical:
          "/hr/workerInformationManagement/dependentInformationManagement/dependentManagement/dependent.add",
        text: "A worker updates an existing dependent.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Dependent",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/dependent.change",
        name: "Change Dependent",
        canonical:
          "/hr/workerInformationManagement/dependentInformationManagement/dependentManagement/dependent.change",
        text: "A worker updates an existing dependent.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Dependent",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/dependent.remove",
        name: "Remove Dependent",
        canonical:
          "/hr/workerInformationManagement/dependentInformationManagement/dependentManagement/dependent.remove",
        text: "A worker removes a dependent.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Dependent",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.birth-date.change",
        name: "Change Birth Date",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.birthdate.change",
        text: "A worker's birth date changed.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Birth Date",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.gender.change",
        name: "Change Gender",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.gender.change",
        text: "A worker's gender changed.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Gender",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.email.add",
        name: "Add Worker Business Email",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.email.add",
        text: "A worker adds a business email.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Business Email",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.email.change",
        name: "Change Worker Business Email",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.email.change",
        text: "A worker changes a business email.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Business Email",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.email.remove",
        name: "Remove Worker Business Email",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.email.remove",
        text: "A worker removes a business email",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Business Email",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.landline.add",
        name: "Add Worker Business Landline",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.landline.add",
        text: "A worker adds a business landline.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Business Landline",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.landline.change",
        name: "Change Worker Business Landline",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.landline.change",
        text: "A worker changes a business landline.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Business Landline",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.landline.remove",
        name: "Remove Worker Business Landline",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.landline.remove",
        text: "A worker removes a business landline.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Business Landline",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.mobile.add",
        name: "Add Worker Business Mobile",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.mobile.add",
        text: "A worker adds a business mobile telephone.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Business Mobile",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.mobile.change",
        name: "Change Worker Business Mobile",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.mobile.change",
        text: "A worker changes a business mobile telephone.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Business Mobile",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.business-communication.mobile.remove",
        name: "Remove Worker Business Mobile",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.mobile.remove",
        text: "A worker removes a business mobile telephone.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Business Mobile",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.worker-original-hire-date.change",
        name: "Change Worker Original Hire Date",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/originalHireDateManagement/worker.workerOriginalHireDate.change",
        text: "Change worker original hire date",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Original Hire Date",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.worker-rehire-date.change",
        name: "Change Worker Rehire Date",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/rehireDateManagement/worker.workerRehireDate.change",
        text: "Change worker rehire date",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Rehire Date",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.worker-termination-date.change",
        name: "Change Worker Termination Date",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/terminationDateManagement/worker.workerTerminationDate.change",
        text: "Change worker termination date",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Termination Date",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.marital-status.change",
        name: "Change Worker Marital Status",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.maritalStatus.change",
        text: "A worker's marital status has changed.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Marital Status",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.legal-name.change",
        name: "Change Legal Name",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.legalName.change",
        text: "A worker changes his/her legal name in a given context.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Legal Name",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.deceased",
        name: "Worker Deceased",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.deceased",
        text: "A worker is now deceased",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Deceased",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.marital-status.change/{event-id}",
        name: "Change Worker Marital Status  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.maritalStatus.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Marital Status",
      },
      {
        verb: "GET",
        path: "/payroll/v1/workers/{aoid}/pay-statements",
        name: "Pay Statements (Non Standard)",
        canonical:
          "/payroll/payrollManagement/statementManagement/payStatementViewing/payStatement.read",
        text: "Request the list of the last n payStatements for an Associate.  Default = payStatements from the last 5 pay dates.",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Statements (Non Standard)",
      },
      {
        verb: "GET",
        path: "/payroll/v1/workers/{aoid}/pay-statements/{pay-statement-id}",
        name: "Pay Statement (Non Standard)",
        canonical:
          "/payroll/payrollManagement/statementManagement/payStatementViewing/payStatement.read",
        text: "Request the details of a specific pay statement",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Statements (Non Standard)",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.add",
        name: "Add Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.add",
        text: "Add pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Add",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.add/meta",
        name: "Add Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.add",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Add",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.replace",
        name: "Replace Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.replace",
        text: "Replace pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Replace",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.replace/meta",
        name: "Replace Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.replace",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Replace",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.modify",
        name: "Modify Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.modify/meta",
        name: "Modify Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions",
        name: "Pay Distributions",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Request all available pay distributions for a worker",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Distributions",
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions/{pay-distribution-id}",
        name: "Pay Distribution",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Returns a pay distribution",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Distributions",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/worker.pay-distribution.change",
        name: "Change Pay Distribution",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.change",
        text: "A worker changes how the pay is distributed for a given payroll agreement",
        type: "regular",
        domain: "payroll",
        subCategory: "Change Pay Distribution",
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Workers Data Collection",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Request the list of all available workers that the requester is authorized to view",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Individual Worker Data",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Returns a worker",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.add",
        name: "Add Additional Remuneration",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.add",
        text: "A worker's additional remuneration (e.g. bonus, commission, etc.) is added.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Additional Remuneration",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.change",
        name: "Change Additional Remuneration",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.change",
        text: "A worker's additional remuneration (e.g. bonus, commission, etc.) is removed.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Additional Remuneration",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.remove",
        name: "Remove Additional Remuneration",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.remove",
        text: "A worker's additional remuneration (e.g. bonus, commission, etc.) is removed.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Additional Remuneration",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.base-remuneration.change",
        name: "Change Base Remuneration",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.baseRemuneration.change",
        text: "A worker's base remuneration is changed.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Base Remuneration",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.identity-document.add/{event-id}",
        name: "Add Worker Identity Document  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.identityDocument.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Identity Document",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.identity-document.change/{event-id}",
        name: "Change Worker Identity Document  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.identityDocument.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Identity Document",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.identity-document.remove/{event-id}",
        name: "Remove Worker Identity Document  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.identityDocument.remove.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Identity Document",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.hire",
        name: "Hire Worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.hire",
        text: "A worker is hired.",
        type: "regular",
        domain: "hr",
        subCategory: "Hire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.hire/{event-id}",
        name: "Hire Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.hire.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Hire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.hire/meta",
        name: "Hire Worker Meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.hire",
        text: "Returns an event metadata",
        type: "meta",
        domain: "hr",
        subCategory: "Hire Worker",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.rehire",
        name: "Rehire Worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "A worker is rehired.",
        type: "regular",
        domain: "hr",
        subCategory: "Rehire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.rehire/{event-id}",
        name: "Rehire Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Rehire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.rehire/meta",
        name: "Rehire Worker Meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Returns an event metadata",
        type: "meta",
        domain: "hr",
        subCategory: "Rehire Worker",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.terminate",
        name: "Terminate Worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.terminate",
        text: "A worker is terminated involuntarily.",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.terminate/{event-id}",
        name: "Terminate Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.terminate.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.terminate/meta",
        name: "Terminate Worker Meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.terminate",
        text: "Returns an event metadata",
        type: "meta",
        domain: "hr",
        subCategory: "Terminate Worker",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker-termination.cancel",
        name: "Cancel Worker Termination",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-termination.cancel",
        text: "A worker  termination cancelled",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Termination",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.legal-address.add",
        name: "Add Worker Legal Address",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.legalAddress.add",
        text: "A worker adds a legal address",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Legal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.legal-address.change",
        name: "Change Worker Legal Address",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.legalAddress.change",
        text: "Worker legal address changes",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Legal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.legal-address.change/{event-id}",
        name: "Change Worker Legal Address Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.legalAddress.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Legal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.legal-address.remove",
        name: "Remove Worker Legal Address",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.legalAddress.remove",
        text: "Worker legal address remove",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Legal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.legal-address.remove/{event-id}",
        name: "Remove Worker Legal Address Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.legalAddress.remove.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Legal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-address.add",
        name: "Add Worker Personal Address",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.add",
        text: "A worker adds a personal address.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-address.change",
        name: "Change Worker Personal Address",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.change",
        text: "Worker personal address changes",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-address.remove",
        name: "Remove Worker Personal Address",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.remove",
        text: "A worker removes a personal address",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.email.add",
        name: "Add Worker Personal Email",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.email.add",
        text: "A worker adds a personal email.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Email",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.email.change",
        name: "Change Worker Personal Email",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.email.change",
        text: "A worker changes a personal email.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Email",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.email.remove",
        name: "Remove Worker Personal Email",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.email.remove",
        text: "A worker removes a personal email.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Email",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.landline.add",
        name: "Add Worker Personal Landline",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.landline.add",
        text: "A worker adds a personal landline.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Landline",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.landline.change",
        name: "Change Worker Personal Landline",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.landline.change",
        text: "A worker changes a personal landline.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Landline",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.landline.remove",
        name: "Remove Worker Personal Landline",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.landline.remove",
        text: "A worker removes a personal landline.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Landline",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.mobile.add",
        name: "Add Worker Personal Mobile",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.mobile.add",
        text: "A worker adds a personal mobile telephone.",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Mobile",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.mobile.change",
        name: "Change Worker Personal Mobile",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.mobile.change",
        text: "A worker changes a personal mobile telephone.",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Mobile",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.personal-communication.mobile.remove",
        name: "Remove Worker Personal Mobile",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.mobile.remove",
        text: "A worker removes a personal mobile telephone.",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Mobile",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-address.add/{event-id}",
        name: "Add Worker Personal Address  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-address.change/{event-id}",
        name: "Change Worker Personal Address  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-address.remove/{event-id}",
        name: "Remove Worker Personal Address  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.remove.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Address",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.add",
        name: "Add Work Assignment",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.add",
        text: "Add work assignment details",
        type: "regular",
        domain: "hr",
        subCategory: "Add Work Assignment",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.terminate",
        name: "Terminate Work Assignment",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.terminate",
        text: "Terminate work assignment details",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Work Assignment",
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.modify",
        name: "Modify Work Assignment",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.modify",
        text: "Modify work assignment details",
        type: "regular",
        domain: "hr",
        subCategory: "Modify Work Assignment",
      },
      {
        verb: "GET",
        path: "/payroll/v1/workers/{aoid}/pay-statements",
        name: "Pay Statements",
        canonical:
          "/payroll/payrollManagement/payStatementManagement/payStatementViewing/payStatement.read",
        text: "Request the list of the last n payStatements for an Associate.  Default = payStatements from the last 5 pay dates.",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Statements",
      },
      {
        verb: "GET",
        path: "/payroll/v1/workers/{aoid}/pay-statements/{pay-statement-id}",
        name: "Pay Statement",
        canonical:
          "/payroll/payrollManagement/payStatementManagement/payStatementViewing/payStatement.read",
        text: "Request the details of a specific pay statement",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Statements",
      },
      {
        verb: "GET",
        path: "/payroll/v1/associates/{aoid}/pay-statements/{pay-statement-id}/images/{image-id}.{image-extension}",
        name: "Pay Statement Image (DEPRECATED)",
        canonical:
          "/payroll/payrollManagement/payStatementManagement/payStatementViewing/payStatement.read",
        text: "Request the details of a specific paystatement as an image",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Statements",
      },
      {
        verb: "GET",
        path: "/payroll/v1/workers/{aoid}/pay-statements/{pay-statement-id}/images/{image-id}.{image-extension}",
        name: "Pay Statement Image",
        canonical:
          "/payroll/payrollManagement/payStatementManagement/payStatementViewing/payStatement.read",
        text: "Request the details of a specific paystatement as an image",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Statements",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/payroll-processing-job.status.change/{event-id}",
        name: "Change Payroll Processing Job Status Event Instance (by Event Manager)",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payrollJobStatusManagement/payrollJobStatus.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "payroll",
        subCategory: "Change Payroll Processing Job Status",
      },
      {
        verb: "GET",
        path: "/payroll/v2/payroll-output",
        name: "Payroll Outputs",
        canonical:
          "/payroll/payrollManagement/payrollProcessingResults/payrollOutputManagement/payrollOutput.read",
        text: "Returns complete payroll output details that the requester is authorized to view based on the supplied filter criteria",
        type: "regular",
        domain: "payroll",
        subCategory: "Payroll Outputs",
      },
      {
        verb: "PUT",
        path: "/hcm/v2/applicants/{applicant-id}/onboarding",
        name: "Update Existing Applicant Onboarding Details (New Hire Applicants)",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicantOnboarding.modify",
        text: "Data related to the applicant new hire onboarding request. A new hire is an applicant who has not previously worked for the organization.",
        type: "regular",
        domain: "hcm",
        subCategory: "New Hire Onboarding Process Management",
      },
      {
        verb: "GET",
        path: "/hcm/v2/applicants/{applicant-id}/onboarding/meta",
        name: "Metadata - Retrieve/Update Existing Applicant Onboarding Request APIs (New Hire Applicants)",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicantOnboarding.meta.read",
        text: "Returns metadata for the retrieve/update existing applicant new hire onboarding APIs",
        type: "meta",
        domain: "hcm",
        subCategory: "New Hire Onboarding API Metadata",
      },
      {
        verb: "POST",
        path: "/hcm/v2/applicant.onboard",
        name: "Initiate Applicant Onboarding (New Hire Applicants)",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicant.onboard",
        text: "Data related to the applicant new hire onboarding request. A new hire is an applicant who has not previously worked for the organization.",
        type: "regular",
        domain: "hcm",
        subCategory: "New Hire Onboarding Process Management",
      },
      {
        verb: "GET",
        path: "/hcm/v2/applicant.onboard/meta",
        name: "Metadata - Initiate Applicant Onboarding API (New Hire Applicants)",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicant.onboard.meta.read",
        text: "Returns metadata for the applicant new hire onboarding API",
        type: "meta",
        domain: "hcm",
        subCategory: "New Hire Onboarding API Metadata",
      },
      {
        verb: "POST",
        path: "/hcm/v2/applicants/{applicant-id}/onboarding.complete",
        name: "Complete Existing Applicant Onboarding (New Hire Applicants)",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicantOnboarding.complete",
        text: "Data related to the applicant new hire onboarding request completion. A new hire is an applicant who has not previously worked for the organization.",
        type: "regular",
        domain: "hcm",
        subCategory: "New Hire Onboarding Process Management",
      },
      {
        verb: "GET",
        path: "/hcm/v2/applicants/{applicant-id}/onboarding.complete/meta",
        name: "Metadata - Applicant Onboarding Request Completion (New Hire Applicants)",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicantOnboarding.complete.meta.read",
        text: "Returns metadata for the existing applicant new hire onboarding completion API",
        type: "meta",
        domain: "hcm",
        subCategory: "New Hire Onboarding API Metadata",
      },
    ],
  },
  {
    header: "Benefits",
    message:
      "Build integrations to help engage employees and streamline benefits program administration and management.",
    illustrationName: "dependant",
    apis: [
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Worker Demographics",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Request the list of all available worker demographics that the requester is authorized to view.",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Worker Demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Returns a worker demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.business-communication.email.add/{event-id}",
        name: "Add Worker Business Email Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.email.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Business Email",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.business-communication.email.change/{event-id}",
        name: "Change Worker Business Email Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.email.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Business Email",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.business-communication.email.remove/{event-id}",
        name: "Remove Worker Business Email Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.businessCommunication.email.remove.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Business Email",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.worker-original-hire-date.change/{event-id}",
        name: "Change Worker Original Hire Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/originalHireDateManagement/worker.workerOriginalHireDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Original Hire Date",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.worker-rehire-date.change/{event-id}",
        name: "Change Worker Rehire Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/rehireDateManagement/worker.workerRehireDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Rehire Date",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.worker-termination-date.change/{event-id}",
        name: "Change Worker Termination Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerDateManagement/terminationDateManagement/worker.workerTerminationDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Termination Date",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.legal-name.change/{event-id}",
        name: "Change Legal Name Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.legalName.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Legal Name",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.add",
        name: "Add Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.add",
        text: "Add pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Add",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.add/meta",
        name: "Add Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.add",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Add",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.replace",
        name: "Replace Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.replace",
        text: "Replace pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Replace",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.replace/meta",
        name: "Replace Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.replace",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Replace",
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.modify",
        name: "Modify Pay Data Input",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify pay data input information",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.modify/meta",
        name: "Modify Pay Data Input Meta",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Returns an event metadata",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/worker.pay-distribution.change/{event-id}",
        name: "Change Pay Distribution  Event Instance (by Event Manager)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "payroll",
        subCategory: "Change Pay Distribution",
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Workers Data Collection",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Request the list of all available workers that the requester is authorized to view",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Individual Worker Data",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Returns a worker",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.hire/{event-id}",
        name: "Hire Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.hire.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Hire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.rehire/{event-id}",
        name: "Rehire Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Rehire Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.terminate/{event-id}",
        name: "Terminate Worker Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.terminate.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Worker",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker-termination.cancel/{event-id}",
        name: "Cancel Worker Termination Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-termination.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Termination",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker-hire.cancel/{event-id}",
        name: "Cancel Worker Hire Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-hire.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Hire",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker-rehire.cancel/{event-id}",
        name: "Cancel Worker Rehire Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker-rehire.cancel.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Cancel Worker Rehire",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-communication.email.add/{event-id}",
        name: "Add Worker Personal Email Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.email.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Email",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-communication.email.change/{event-id}",
        name: "Change Worker Personal Email Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.email.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Email",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-communication.email.remove/{event-id}",
        name: "Remove Worker Personal Email Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/communicationManagement/worker.personalCommunication.email.remove.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Email",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-address.add/{event-id}",
        name: "Add Worker Personal Address  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Worker Personal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-address.change/{event-id}",
        name: "Change Worker Personal Address  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Worker Personal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.personal-address.remove/{event-id}",
        name: "Remove Worker Personal Address  Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/addressManagement/worker.personalAddress.remove.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Remove Worker Personal Address",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.add/{event-id}",
        name: "Add Work Assignment Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.add.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Add Work Assignment",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.terminate/{event-id}",
        name: "Terminate Work Assignment Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.terminate.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Terminate Work Assignment",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.hire-date.change/{event-id}",
        name: "Change Work Assignment Hire Date Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.hireDate.change.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Change Work Assignment Hire Date",
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.modify/{event-id}",
        name: "Modify Work Assignment Event Instance (by Event Manager)",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workAssignmentManagement/worker.workAssignment.modify.read",
        text: "Returns an event instance identified by the {event-id} (hosted by Event Manager)",
        type: "regular",
        domain: "hr",
        subCategory: "Modify Work Assignment",
      },
      {
        verb: "GET",
        path: "/payroll/v2/payroll-output",
        name: "Payroll Outputs",
        canonical:
          "/payroll/payrollManagement/payrollProcessingResults/payrollOutputManagement/payrollOutput.read",
        text: "Returns complete payroll output details that the requester is authorized to view based on the supplied filter criteria",
        type: "regular",
        domain: "payroll",
        subCategory: "Payroll Outputs",
      },
      {
        verb: "GET",
        path: "/time/v3/workers/{aoid}/time-off-requests",
        name: "Worker Time Off Requests",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.read",
        text: "Request the list of all available worker time off requests that the requester is authorized to view",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Requests",
      },
      {
        verb: "GET",
        path: "/time/v3/workers/{aoid}/time-off-requests/{time-off-request-id}",
        name: "Worker Time Off Request",
        canonical:
          "/time/timeLaborManagement/timeOffManagement/timeOffRequestManagement/timeOffRequest.read",
        text: "Returns a single time off request",
        type: "regular",
        domain: "time",
        subCategory: "Worker Time Off Requests",
      },
      {
        verb: "POST",
        path: "/core/v2/associate-document-management/associates/{aoid}/document-uploads",
        name: "Upload a Document object",
        canonical:
          "/core/organizationInformationManagement/workerDocumentManagement/documentManagement/document.add",
        text: "Upload a Document object - metadata and binary content stream (file)",
        type: "regular",
        domain: "core",
        subCategory: "Employee Document Management",
      },
      {
        verb: "POST",
        path: "/core/v2/organization-document-management/document-uploads",
        name: "Upload a Document object",
        canonical:
          "/core/organizationInformationManagement/organizationDocumentManagement/documentManagement/document.add",
        text: "Upload a Document object - metadata and binary contentstream (file)",
        type: "regular",
        domain: "core",
        subCategory: "Company Document Management",
      },
      {
        verb: "GET",
        path: "/core/v2/associate-document-management/document-categories",
        name: "Retrieve list of all Document categories available for Employee-level documents",
        canonical:
          "/core/organizationInformationManagement/workerDocumentManagement/documentManagement/categories.read",
        text: "Retrieve list of all Document categories available for Employee-level documents",
        type: "regular",
        domain: "core",
        subCategory: "Employee Document Category Management",
      },
      {
        verb: "GET",
        path: "/core/v2/organization-document-management/document-categories",
        name: "Retrieve list of all Document categories available for Company-level documents",
        canonical:
          "/core/organizationInformationManagement/organizationDocumentManagement/documentManagement/categories.read",
        text: "Retrieve list of all Document categories available for Company-level documents",
        type: "regular",
        domain: "core",
        subCategory: "Company Document Category Management",
      },
    ],
  },
];

const needItemListUs = [
  {
    header: "Instant access to APIs",
    message:
      "API Central can be purchased online and digitally activated in minutes, giving instant access to your workforce data via ADP APIs.",
    illustrationName: "ext-mdi-assured-workload",
  },
  {
    header: "ADP API library",
    message:
      "Access ADP APIs across all supported ADP platforms, covering common use cases for custom integration.",
    illustrationName: "toolbox",
  },
  {
    header: "API use case templates",
    message:
      "Jumpstart your integration projects. Select a use case for your project and it will immediately populate with relevant APIs.",
    illustrationName: "ext-mdi-dashboard-customize",
  },
  {
    header: "API discovery tool",
    message:
      "Instantly add new APIs to any ADP API Central integration project. Once you add a new API, code samples are immediately available.",
    illustrationName: "action-search",
  },
  {
    header: "Developer resources",
    message:
      "Access documentation, how-to guides, code collection samples, developer libraries and more.",
    illustrationName: "ext-mdi-data-object",
  },
  {
    header: "Data access credentials",
    message:
      "Quickly create integration projects and security certificates and add development team members (internal or external).",
    illustrationName: "media-file",
  },
  {
    header: "Ticketed support",
    message:
      "Responsive experts monitor our ticketed support channel to help keep your project on track.",
    illustrationName: "action-chat-person",
  },
  {
    header: "Data security",
    message:
      "API Central leverages OpenID Connect and OAuth 2.0 to ensure only authorized users and systems can access your data.",
    illustrationName: "ext-mdi-laptop",
  },
];

const needItemListInt = [
  {
    header: "Instant access to APIs",
    message:
      "After you purchase and activate API Central, you’ll have instant access to your workforce data via ADP APIs.",
    illustrationName: "ext-mdi-assured-workload",
  },
  {
    header: "ADP API library",
    message:
      "Access ADP APIs across all supported ADP platforms, covering common use cases for custom integration.",
    illustrationName: "toolbox",
  },
  {
    header: "API integration use cases",
    message:
      "Jumpstart your integration projects. Select a use case, define the scope of your project, and get access to the relevant APIs.",
    illustrationName: "ext-mdi-dashboard-customize",
  },
  {
    header: "Data security",
    message:
      "API Central leverages OpenID Connect and OAuth 2.0 to ensure only authorized users and systems can access your data.",
    illustrationName: "ext-mdi-laptop",
  },
  {
    header: "Developer resources",
    message:
      "Access documentation, how-to guides, code collection samples, developer libraries and more.",
    illustrationName: "ext-mdi-data-object",
  },
  {
    header: "Data access credentials",
    message:
      "Work with our team to quickly generate security certificates and add development team members (internal or external).",
    illustrationName: "media-file",
  },
  {
    header: "Ticketed support",
    message:
      "Use our ticketing system to get support from ADP specialists on APIs, implementation questions, best practices, and more.",
    illustrationName: "action-chat-person",
  },
];

export { startItemListUs, startItemListInt, needItemListUs, needItemListInt };
