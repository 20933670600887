import gears from "../../../assets/images/partner-landing/gears.svg";
import tablet from "../../../assets/images/partner-landing/tablet.svg";
import target from "../../../assets/images/partner-landing/target.svg";
import connections from "../../../assets/images/partner-landing/connections.svg";
import image from "../../../assets/images/partner-landing/partner-landing-page-img.png";
import in_image from "../../../assets/images/partner-landing/partner-landing-international.jpg";
import "../style.css";

export const CLIENT_OPTIONS = [
  {
    icon: connections,
    title: "A substantial number of shared clients",
  },
  {
    icon: target,
    title: "Differentiated HCM solutions",
  },
  {
    icon: gears,
    title:
      "A SaaS technology platform that can be integrated with ADP systems via APIs",
  },
  {
    icon: tablet,
    title: "Digital purchasing capabilities",
  },
];



export const NA_HEADING = "Interested in becoming an ADP Marketplace partner?";
export const IN_HEADING =
  "Interested in becoming an international ADP Marketplace partner?";
export const NA_PRODUCTS =
  "View code samples of requests and responses from APIs for ADP products including ADP Workforce Now®, ADP Vantage HCM®, and more.";
export const IN_PRODUCTS =
  "View code samples of requests and responses from APIs for ADP products including ADP DECIDIUM®, ADP® Link, ADP® AdvancedPay, ADP® iHCM, ​ADP GlobalView®, ADP® OneService and more.​";

export const NA_imageProps = {
  src: image,
  alt: "header",
  partnerLanding: true,
  linkList: [
    {
      title: (
        <>
          ADP Workforce Now<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn",
      className: "wfn",
    },
    {
      title: (
        <>
          ADP Workforce Now<sup className="sup">®</sup> Next Generation
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn.next.gen",
      className: "nextgen",
    },
    {
      title: (
        <>
          RUN Powered by ADP<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/pr-offrg-run",
      className: "run",
    },
    {
      title: <>ADP Lyric</>,
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-lifion",
      className: "hcm",
    },
    {
      title: (
        <>
          ADP Vantage HCM<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-vantg",
      className: "vantage",
    },
    {
      title: (
        <>
          ADP Enterprise HR<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-ent",
      className: "enterprise",
    },
    {
      title: (
        <>
          ADP SmartCompliance<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/cp-offrg-smart.cp",
      className: "compliance",
    },
    {
      title: (
        <>
          ADP<sup className="sup">®</sup> Recruiting Management
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/tal-offrg-tal.rcrtg",
      className: "recruiting",
    },
  ],
};

export const IN_imageProps = {
  src: in_image,
  alt: "header",
  partnerLanding: true,
  linkList: [
    {
      title: (
        <>
          ADP<sup className="sup">®</sup> AdvancedPay
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/pr-offrg-paisy.advd",
      className: "wfn-in",
    },
    {
      title: (
        <>
          ADP DECIDIUM<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-dge",
      className: "nextgen-in",
    },
    {
      title: (
        <>
          ADP eXpert<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-adp.xprt-g.br",
      className: "run-in",
    },
    {
      title: (
        <>
          ADP GlobalView<sup className="sup">®</sup>
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/pr-offrg-glob.vw",
      className: "hcm-in",
    },
    {
      title: (
        <>
          ADP<sup className="sup">®</sup> iHCM
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-ihcm.2",
      className: "vantage-in",
    },
    {
      title: (
        <>
          ADP<sup className="sup">®</sup> Link
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/pr-offrg-adp.link.prem",
      className: "enterprise-in",
    },
    {
      title: (
        <>
          ADP<sup className="sup">®</sup> Workforce
        </>
      ),
      linkText: "View related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wkfc",
      className: "recruiting-in",
    },
  ],
};

export const headerData = [
  {
    title: NA_HEADING,
    products: NA_PRODUCTS,
    imageProps: NA_imageProps,
    api: "hcm-offrg-wfn"
  },
  {
    title: IN_HEADING,
    products: IN_PRODUCTS,
    imageProps: IN_imageProps,
    api: "hcm-offrg-dge"
  },
];

export const NA_GUIDE_ITEM_LIST = [
  {
    header: "Human resources",
    message:
      "Sensitive and non-sensitive personal data, demographic, employment and pay-related data",
    pathName: "/guides/api-guides?useCaseTags=Human-Resources",
  },
  {
    header: "Time & labor management",
    message:
      "Time and attendance, workforce management,  timeclock punches, shift scheduling",
    pathName: "/guides/api-guides?useCaseTags=Time-Labor-Management",
  },
  {
    header: "Payroll management",
    message:
      "Data needed for payroll processing, such as regular hours and overtime hours, as well as processed data, such as earnings, deductions, taxes and more",
    pathName: "/guides/api-guides?useCaseTags=Payroll-Management",
  },
  {
    header: "Recruiting",
    message:
      "Data related to job requisitions, applications and assessments, background checks, hiring, onboarding",
    pathName: "/guides/api-guides?useCaseTags=Recruiting",
  },
  {
    header: "Benefit management",
    message:
      "Employee benefits, deductions, dependents, and insurance management",
    pathName: "/guides/api-guides?useCaseTags=Benefit-Management",
  },
];

export const IN_GUIDE_ITEM_LIST = [
  {
    header: "API & Data Connectors",
    message:
      "Integrate ADP data with your HR and business solutions. Build solutions to synchronize employee records, payroll information, organizational structures, and more, in real time or through batch processing.",
    pathName: "/guides/api-guides?useCaseTags=API+%26+Data+Connectors",
  },
  {
    header: "Compensation",
    message:
      "Manage employee compensation, including salaries, bonuses, and profit sharing. Build solutions to streamline compensation planning, automate payroll adjustments, and help ensure compliance. ",
    pathName: "/guides/api-guides?useCaseTags=Compensation",
  },
  {
    header: "Engagement & Benefits",
    message:
      "Build integrations to help engage employees and streamline benefits program administration and management. ",
    pathName: "/guides/api-guides?useCaseTags=Engagement+%26+Benefits",
  },
  {
    header: "Finance",
    message:
      "Bridge HR and financial systems by ensuring a seamless integration for payroll accounting. Build solutions that offer financial flexibility through earned wage access, fleet management, and more. ",
    pathName: "/guides/api-guides?useCaseTags=Finance",
  },
  {
    header: "Learning Management",
    message:
      "Support continuous learning and employee development, including course enrollment, training progress, and certification tracking. Build solutions to offer learning, track outcomes, and support compliance. ",
    pathName: "/guides/api-guides?useCaseTags=Learning+Management",
  },
  {
    header: "Performance",
    message:
      "Help facilitate employee performance management including goal setting, performance evaluations, and feedback.  ",
    pathName: "/guides/api-guides?useCaseTags=Performance",
  },
  {
    header: "Productivity & Collaboration",
    message:
      "Integrate HR data with productivity and collaboration systems. Build solutions to automate workflows, simplify administrative tasks, streamline document management, and improve team communication. ",
    pathName: "/guides/api-guides?useCaseTags=Productivity+%26+Collaboration",
  },
  {
    header: "Recruitment & Onboarding",
    message:
      "Simplify the hiring process, from job posting to onboarding new hires. Build solutions to automate recruitment workflows, track candidate progress, and provide smooth onboarding experiences. ",
    pathName: "/guides/api-guides?useCaseTags=Recruitment+%26+Onboarding",
  },
  {
    header: "Time & Activites Management",
    message:
      "Help track employee time and attendance, manage leave and absence, and more for accurate timekeeping and payroll processing. Build time data integrations between ADP and other systems. ",
    pathName: "/guides/api-guides?useCaseTags=Time+%26+Activities+Management",
  },
  {
    header: "Travel & Expense Management",
    message:
      "Build integrations between ADP and other systems to simplify expense submission and approval, automate reimbursements, and integrate with payroll. ",
    pathName: "/guides/api-guides?useCaseTags=Travel+%26+Expense+Management",
  },
];

export const guideData = [{
  guideList: NA_GUIDE_ITEM_LIST,
  guideButton: "View all product guides for North America",
}, {
  guideList: IN_GUIDE_ITEM_LIST,
  guideButton: "View product guides by domain",
}]

export const NA_FAQ_ITEMS = [
  {
    header:
      "What is an ADP Marketplace partner? How can I become an ADP Marketplace partner? ",
    detail: `ADP Marketplace partners are third-party companies that offer integrated HR solutions to ADP clients via ADP Marketplace, our digital HR storefront. Partner solutions are integrated with ADP products via API.<br /><br />
      Our exclusive partner program is by application only, and we are looking for prospective partners who have: <br />
      &nbsp;&nbsp; &#8226; &nbsp; A substantial number of shared clients. <br />
      &nbsp;&nbsp; &#8226; &nbsp; Differentiated HCM solutions. <br />
      &nbsp;&nbsp; &#8226; &nbsp; A software-as-a-service technology platform that can be integrated with ADP systems via APIs. <br />
      &nbsp;&nbsp; &#8226; &nbsp; Digital purchasing capabilities. <br /><br />
      If your company’s solution meets these criteria, you can <a target="_blank" rel="noopener noreferrer" href="https://partners.adp.com/gettingstarted/">submit your detailed application here</a> and our team will reach out if it may be a good fit. If your solution doesn't fit with our current objectives, we'll keep your application on file and may re-evaluate it if our criteria change. Given the volume of applications we receive, please allow time for our team to review and reply.`,
  },
  {
    header: "What APIs can partners use to build integrations with ADP?",
    detail: `Developers have access to a variety of ADP APIs that span the employee life cycle including HR, time and labor management, payroll, recruiting, benefits, and more. Available APIs vary by ADP product, such as 
    ADP Workforce Now®, RUN Powered by ADP®, ADP Vantage HCM®
    , ADP Lyric, and others. Explore all APIs here: <a class="break-all" target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/apis/api-explorer">https://developers.adp.com/apis/api-explorer.</a>`,
  },
  {
    header: "What is the average time to complete an integration with ADP?",
    detail: `After you’re accepted into the ADP Marketplace partner program, you’ll determine milestones and create a project plan to build your integrations. Your timeline will largely depend on your team and resources. Many highly engaged and motivated partners have completed their projects in three to six months, excluding security review or other activities required to go live, but this varies by partner. `,
  },
  {
    header: "As an ADP Marketplace partner, how do I start calling ADP APIs?",
    detail: `After you’re accepted into the ADP Marketplace partner program, you’ll complete a sandbox agreement and gain access to our Partner Self-Service portal to scope and plan your project. In the portal, you’ll create a project to receive partner credentials, generate a mutual SSL certificate, and request access to a sandbox. Once you have access, you can <a target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/guides/adp-marketplace-integration-guides/Making-Your-First-API-Call-With-POSTMAN">refer to this guide for making your first API call</a> with Postman. Please note that you won’t be able to build out your full integration until you’ve signed the developer participation agreement, which outlines the terms of the partnership.`,
  },
  {
    header: "What help will I receive from ADP in my integration journey?",
    detail: `
    ADP Marketplace partners have access to a variety of resources to ensure the app integration process goes smoothly and you’re set up for success. While you’re building your integrations, you’ll have access to: <br />

    &nbsp;&nbsp; &#8226; &nbsp; Dedicated support from our team. <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; Access to the Partner Self-Service tool, where you’ll access ADP APIs, manage credentials, create projects, generate security certificates, and more. <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; Access to ADP’s API library and robust how-to articles. <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; At least two rounds of app certification to meet ADP’s security requirements. <br />
    `,
  },
];

export const IN_FAQ_ITEMS = [
  {
    header:
      "What is an ADP Marketplace partner? How can I become an ADP Marketplace partner? ",
    detail: `ADP Marketplace partners are third-party companies that offer integrated HR solutions to ADP clients. All ADP Marketplace partners have signed a partnership contract with ADP to offer their own HR solutions or offer their integration development services using ADP APIs.<br /> <br />
    Our exclusive partner program is by application only, and we are looking for prospective partners who have: <br />
      &nbsp;&nbsp; &#8226; &nbsp; A substantial number of shared clients. <br />
      &nbsp;&nbsp; &#8226; &nbsp; Differentiated HCM solutions. <br />
      &nbsp;&nbsp; &#8226; &nbsp; A software-as-a-service technology platform that can be integrated with ADP systems via APIs. <br />`
  },
  {
    header: "What is the difference between ADP Marketplace in North America and internationally?",
    detail: `The concept of ADP Marketplace is the same in all countries where it’s available, however, available solutions and integration capabilities vary from region to region. There are also different storefronts depending on clients’ location.`
    },
  {
    header: "What APIs can partners use to build integrations with ADP?",
    detail: `Developers have access to a variety of ADP APIs that span the employee life cycle including HR, time and labor management, payroll, recruiting, benefits, and more. Available APIs vary by ADP product. Explore all APIs here: <a class="break-all" target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/apis/api-explorer">https://developers.adp.com/apis/api-explorer.</a>`,
  },
  {
    header:
      "What is the average time to complete an integration with ADP?",
    detail: `
    The timeline will largely depend on each partner team and resources. Many highly engaged and motivated partners have completed their projects in three to six months, excluding security review or other activities required to go live, but this varies by partner.
    `,
  },
  {
    header:
      "What help will ADP Marketplace partners receive from ADP in their integration journey?",
    detail: `
    ADP Marketplace partners have access to a variety of resources to ensure the app integration process goes smoothly and is set up for success. While partners are building integrations, they’ll have access to: <br />

    &nbsp;&nbsp; &#8226; &nbsp;Dedicated support from ADP. <br />
    
    &nbsp;&nbsp; &#8226; &nbsp;Access to the ADP API technology library, documentation, guidance, and at least two rounds of app certification to meet ADP’s security requirements.  <br />`,
  },
];
