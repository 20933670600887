import React from "react";
import { SdfBox, SdfIcon, SdfButton } from "@synerg/react-components";
import "../welcome-page/style.css";
import { CLIENT_OPTIONS } from "./utility/constants";

export function ClientSection({ region }) {

  return (
    <div className="w-10/12 md:w-4/5 py-20 text-center">
      <div className="flex flex-col items-center ">
        <h2 className="text-heading-06 font-black mb-10">
          Benefiting our mutual clients
        </h2>
        <p className="text-body-03 body-02 mb-8 md:w-4/5 xl:w-11/12">
          Do you provide awesome, industry-leading HCM or business solutions? Do
          a lot of your clients use ADP payroll? Integrating your solution with
          ADP can help you reach new clients and help current clients get more
          value from your product. We carefully evaluate all applications for
          strategic fit with our partner program objectives.
        </p>

        {region === 0 && (
          <>
            {" "}
            <h3 className="text-heading-05 font-bold mb-8">
              We are looking for prospective partners who have:
            </h3>
            <div className="grid grid-cols-2 auto-rows-fr gap-4">
              {CLIENT_OPTIONS.map((options, idx) => (
                <SdfBox variant="bordered" spacing="loose" key={idx}>
                  <div className="flex flex-wrap xl:flex-nowrap items-center gap-4">
                    <img className="img-size" src={options.icon} alt="TODO" />
                    <div className="font-bold text-heading-04 text-left">
                      {options.title}
                    </div>
                  </div>
                </SdfBox>
              ))}
            </div>
            <div className="mt-12 flex justify-center space-x-4">
              <SdfButton
                action="standard"
                emphasis="primary"
                size="sm"
                variant="contained"
                onClick={() =>
                  window.open("https://partners.adp.com/gettingstarted/")
                }
              >
                <SdfIcon icon="external-link" className="pr-1" />
                Start application
              </SdfButton>
              <SdfButton
                action="standard"
                emphasis="secondary"
                size="sm"
                variant="contained"
                onClick={() => window.open("https://apps.adp.com/en-US/home")}
              >
                <SdfIcon icon="external-link" className="pr-1" />
                Explore ADP Marketplace
              </SdfButton>
            </div>{" "}
          </>
        )}
        {region === 1 && (
          <div className="flex justify-center space-x-4">
            <SdfButton
              action="standard"
              emphasis="primary"
              size="sm"
              variant="contained"
              onClick={() =>
                window.open("https://forms.office.com/r/DymckjMMcU")
              }
            >
              <SdfIcon icon="external-link" className="pr-1" />
              Start discovery form
            </SdfButton>
          </div>
        )}
      </div>
    </div>
  );
}
