import image from "../../assets/images/new-codeblock.png";
import Breadcrumb from "../Breadcrumb";

const Header = () => {
  const breadcrumbPath = [
    { name: "Home", route: "/" },
    {
      name: "Discover ADP API integrations",
      route: "/APIs/API Explorer/Discover ADP API integrations",
    },
  ];

  return (
    <>
      <div className="w-full flex flex-col items-center text-inverse-default bg-action-primary-900">
        <div className="w-11/12 md:w-4/5 pb-4 overflow-hidden">
          <div className="grid grid-cols-1 lg:grid-cols-2 auto-rows-fr gap-8">
            <div className="py-16">
              <h1 className="m-0 text-heading-05">
                API explorer
                <span className="block m-0 py-2 text-heading-07">
                  Develop integrations with ADP APIs
                </span>
              </h1>
              <p className="m-0 text-body-03">
                Choose a category below to view supported APIs and specific
                payload samples.
              </p>
            </div>
            <div className="hidden lg:block ml-12 my-2 max-w-sm relative">
              <img
                className="absolute left-0 top-0 w-full"
                src={image}
                alt="An example of an ADP worker JSON object"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex pl-10">
        <Breadcrumb path={breadcrumbPath} />
      </div>
    </>
  );
};

export default Header;
