import {useNavigate} from "react-router-dom";
import {getPathName} from "../../routes/legacyRedirects";
import {useEffect} from "react";

export function GuideChapterPath() {
	const navigate = useNavigate()
	const pathName = getPathName()
	const splitedPathName = pathName.split('/')

	useEffect(() => {
		const newGuideType = {
			'marketplace-integration-guides': 'adp-marketplace-integration-guides',
			'product-integration-guides': 'api-guides'
		}[splitedPathName[3]];

		let newPathName = `/guides/${newGuideType}/${splitedPathName[4]}/chapter/${splitedPathName[6]}`;

		navigate(newPathName)
	})
}