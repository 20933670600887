import React from "react";

import {TooltipTag} from "../TooltipTag";

export function ArticleTags({article}) {
	return (
		<div className="flex">
			{
				article?.tagName && <TooltipTag text={article?.tagName}/>
			}
			{
				article?.productName &&
				<TooltipTag text={article?.productName} isApi={article?._index === "api"} />
			}
		</div>
	)
}