import React, { useState } from "react";
import { SdfSelectSimple, SdfLink, SdfIcon } from "@synerg/react-components";
import { InputWidthEnum } from "../../utility/enums/InputWidthEnum";
import logo from "../../assets/images/Purple-corporate-graphic.svg";
import { useNavigate } from "react-router-dom";

export function HeroHeader({ integrationOptions }) {
  const [selectedValue, setSelectedValue] = useState("2");
  const navigate = useNavigate();

  const getSelectedValue = (event) => {
    setSelectedValue(event.target.value.value);
  };

  const handleClick = () => {
    if(selectedValue === "0"){
      navigate("/getting-started/client-integration-overview")
    } else if(selectedValue === "1" ) {
      navigate("/getting-started/partner-integration-overview")
    } else {
      navigate("#")
    }
  };

  return (
    <div className="flex items-center bg-action-default-50 pl-4 md:pl-5 lg:pl-5 pr-8 h-auto pt-10 pb-8 m-0">
      <main className="m-auto items-center flex flex-col min-w-4/5">
        <section className="text-center">
          <div>
            <h1
              className="font-black text-heading-08 m-0"
              id="heroHeader"
            >
              Integrate with ADP’s API Platform
            </h1>
          </div>
          <div
            className="pt-3 text-center sm:text-heading-04"
            id="heroDescription"
          >
            Let us personalize your developer experience by answering the
            question below
          </div>
          <div className="text-center relative">
            <div className="logo--container">
              <img
                src={logo}
                alt=""
                className="object-contain w-full"
              />
            </div>
            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row h-full w-4/5 mx-auto items-center mt-12 gap-4">
              <SdfSelectSimple
                id="demo-component"
                items={integrationOptions.map((options) => {
                  return { label: options.label, value: options.value };
                })}
                inputWidth_md={InputWidthEnum.FULL}
                label=""
                portal-enabled
                required-state="default"
                selectedIndex={selectedValue}
                placeholder="How do you want to use ADP APIs?"
                className="w-4/5 cursor-pointer"
                onSdfChange={(event) => getSelectedValue(event)}
              ></SdfSelectSimple>
              <SdfLink
                href="#"
                id="demo-component"
                emphasis="primary"
                title="Select API"
                className="w-4/5 m-0 p-0 md:w-32 lg:w-32 xl:w-32"
                onClick={() => handleClick()}
              >
                Let's go
                <SdfIcon className="pl-2" icon="nav-move-next"></SdfIcon>
              </SdfLink>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
