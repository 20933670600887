import { createContext, useEffect, useState } from "react";
import { SdfButton } from "@synerg/react-components";

import "./TopButton.css";

/**
 * @type React.Context<{
 *  offsetHeight: number,
 *  setOffsetHeight: (height: number) => void,
 * }>
 */
const TopButtonContext = createContext(null);

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * @param {{ children: React.ReactNode }} props
 */
const TopButton = ({ children }) => {
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [, setExtraOffset] = useState(10);
  const [isAtTop, setIsAtTop] = useState(true);
  const [computedBottom, setComputedBottom] = useState(20);
  const [isAbsolute, setIsAbsolute] = useState(false);

  const updateOffsetHeight = () => {
    const footer = document.querySelector("footer");
    const buyNowComponent = document.querySelector(".buy-now-component");

    let newExtraOffset = 10;
    let bottomSpacing = 20;
    let shouldBeAbsolute = false;
    let useStickyComponent = false;

    if (buyNowComponent) {
      newExtraOffset = buyNowComponent.offsetHeight + 15;
      const bottomRect = buyNowComponent.getBoundingClientRect();
      if (bottomRect.top < window.innerHeight) {
        bottomSpacing = newExtraOffset;
        useStickyComponent = true;
      }
    }

    if (footer && !useStickyComponent) {
      const footerRect = footer.getBoundingClientRect();
      if (footerRect.top < window.innerHeight) {
        shouldBeAbsolute = true;
        bottomSpacing = footer.offsetHeight + 20;
      }
    }

    setExtraOffset(newExtraOffset);
    setOffsetHeight(footer ? footer.offsetHeight : 0);
    setComputedBottom(bottomSpacing);
    setIsAbsolute(shouldBeAbsolute);
  };

  useEffect(() => {
    updateOffsetHeight();

    window.addEventListener("resize", updateOffsetHeight);
    window.addEventListener("scroll", updateOffsetHeight);

    return () => {
      window.removeEventListener("resize", updateOffsetHeight);
      window.removeEventListener("scroll", updateOffsetHeight);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <TopButtonContext.Provider value={{ offsetHeight, setOffsetHeight }}>
      {children}
      {!isAtTop && (
        <SdfButton
          emphasis="primary"
          iconOnly={true}
          icon="nav-move-up"
          onClick={scrollToTop}
          className="fixed m-4 md:pl-10 sm:pl-10 xs:pl-10 right-0 top-button"
          style={{
            position: isAbsolute ? "absolute" : "fixed",
            bottom: `${computedBottom}px`,
          }}
          aria-label="Scroll to top"
        />
      )}
    </TopButtonContext.Provider>
  );
};
export default TopButton;
export { TopButtonContext };
