import React from "react";
import {SdfTooltip} from "@synerg/react-components";

export function TooltipTag({text, isApi}) {
	let substring = '';
	if (!isApi && text?.length > 15) {
		 const words = text.split(' ');
		 substring = `${words[0]} ${words[1]}`
	}

	return (
		<div className="text-xs bg-default border-solid rounded-2xl py-2 px-3 text-alert-neutral mx-1">
			<SdfTooltip
				attachmentPoint="bottom"
				trigger="hover"
			>
			<span slot="tooltip-target" className="truncate uppercase">
				{substring ? substring : text}
			</span>
			{text}
		</SdfTooltip>
		</div>
	)
}