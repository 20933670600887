import { useEffect, useRef, useState } from "react";

import Timeline from "../shared/Timeline";
import "./BuildingIntegrations.css";

const timelineItemsUs = [
  {
    header: "Purchase",
    message: "Purchase ADP API Central for your company.",
    illustrationName: "shopping-basket",
    details:
      "An HR practitioner at your organization will purchase ADP API Central for your company through ADP Marketplace or your ADP representative.",
  },
  {
    header: "Sign in",
    message:
      "Use your existing ADP credentials to sign in to the API Central application.",
    illustrationName: "browser-locked",
    details:
      "The practitioner who purchased the solution will use their ADP credentials to log in to API Central and assign at least one user so developers can get started.",
  },
  {
    header: "Add members",
    message:
      "Add internal and external* development team members and assign user roles.",
    illustrationName: "gears",
    details:
      "Add internal or external team members to assist with your integration projects and assign their user roles.",
  },
  {
    header: "Create a project",
    message:
      "Start with a popular API use case template and add more APIs as you need them.",
    illustrationName: "clipboard-gear",
    details:
      "When beginning a new project, you’ll select from a set of popular API use case templates, each pre-populated with relevant APIs.\n\nUse the API discovery tool to instantly add more APIs if you need them.",
  },
  {
    header: "Build",
    message:
      "Develop your API integration using in-house talent or a third-party integrator.",
    illustrationName: "laptop-code",
    details:
      "Build your API integration. If you’re short on in-house resources, you can hire ADP API specialists to help you get started or experienced ADP Marketplace partners to build your integration entirely.",
  },
  {
    header: "Launch",
    message: "Push your integration code live into a production environment.",
    illustrationName: "high-five",
    details:
      "After your code is complete, you’re ready to start utilizing your integration.",
  },
];

const timelineItemsInt = [
  {
    header: "Discovery",
    message: "Express interest in accessing ADP APIs.",
    illustrationName: "magnifying-glass",
    details:
      "Complete our integration discovery form and an ADP representative will contact you to discuss your integration goals in more detail.",
  },
  {
    header: "Determine scope",
    message:
      "Identify the APIs you’ll need for your project(s).",
    illustrationName: "clipboard-gear",
    details:
      "Discuss your organization’s integration goals in depth with ADP specialists. Select from dozens of use cases and determine the APIs you’ll need to build your integrations.",
  },
  {
    header: "Purchase",
    message:
      "Purchase ADP API Central for your organization.",
    illustrationName: "documents-pencil",
    details:
      "When your company is ready to buy API Central, our team will prepare a contract for you to sign. After that, you’ll get access to APIs and guidance to jumpstart your integration journey.",
  },
  {
    header: "Get API access",
    message:
      "Complete onboarding to our API platform.",
    illustrationName: "gears",
    details:
      "Work with our team to begin accessing your ADP data via APIs. You’ll complete a certificate signing request, get credentials, and more.",
  },
  {
    header: "Build",
    message:
      "Start developing using in-house IT or a third-party integrator.",
    illustrationName: "laptop-code",
    details:
      "Use internal resources or a third party to start building your integrations. Access detailed documentation to help get started and submit tickets for technical issues as needed.\n\nIf you need additional APIs for a project, request them at any time for no extra cost.",
  },
  {
    header: "Launch",
    message: "Use your developed integration in production.",
    illustrationName: "high-five",
    details:
      "When you’re ready to launch your project, inform ADP. Go-live processes may vary depending on the ADP system of record used for integration.\n\nAfter you launch, work with our team for any ongoing support.",
  },
];

/**
 * @param {{ international: boolean }} props
 */
const BuildingIntegrations = ({ international }) => {
  const timelineRef = useRef(null);
  const [timelineWidth, setTimelineWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setTimelineWidth(entry.contentRect.width);
    });

    if (timelineRef.current) {
      resizeObserver.observe(timelineRef.current);
    }

    return () => {
      if (timelineRef.current) {
        resizeObserver.unobserve(timelineRef.current);
      }
    };
  }, [timelineRef.current]);

  return (
    <div className="w-full bg-action-default-50">
      <div className="w-11/12 md:w-4/5 flex flex-col m-auto py-12">
        <h2 className="mx-auto my-0 text-heading-06">
          Building integrations with ADP APIs
        </h2>
        <p className="mx-auto my-8 text-body-02">
          ADP API Central makes it easy to access ADP APIs, tools and services,
          all in one place. Here's how it works.
        </p>
        <Timeline
          timelineItems={international ? timelineItemsInt : timelineItemsUs}
          {...{
            timelineRef,
            homePageTimeline: false,
            cursor: true,
          }}
        />
        <div
          className="building-integration-asterisk"
          style={{ "--timelineWidth": `${timelineWidth}px` }}
        >
          <div className="m-0 pl-2 text-heading-02 text-tertiary">
            * As an ADP API Central client, you have the option to hire
            experienced ADP Marketplace partners to help build and manage your
            integrations.
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingIntegrations;
