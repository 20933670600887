import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { BusyPageIndicator } from "../BusyPageIndicator";

/**
 * @param {{
 *  loading?: boolean
 *  loadTime?: number
 *  loadOnSearch ?: boolean
 *  children?: React.ReactNode
 * }} props
 */
const PageLoading = ({ loading, loadTime, loadOnSearch, children }) => {
  const { pathname, search } = useLocation();
  const [showLoading, setShowLoading] = useState(true);
  const [lastPath, setLastPath] = useState(pathname);
  const [lastSearch, setLastSearch] = useState(search);

  useEffect(() => {
    if (lastPath !== pathname) {
      setShowLoading(true);
      setLastPath(pathname);
    } else if (loadOnSearch && lastSearch !== search) {
      setShowLoading(true);
      setLastSearch(search);
    }
  }, [pathname, search]);

  useEffect(() => {
    if (!loading && showLoading) {
      const timeout = setTimeout(() => {
        setShowLoading(false);
      }, loadTime || 300);
      return () => clearTimeout(timeout);
    } else {
      setShowLoading(false);
    }
  }, [loading, loadTime, showLoading]);

  if (loading || showLoading) {
    return <BusyPageIndicator positionClass="absolute-center" />;
  }

  return children;
};

export default PageLoading;