import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SfcShellTopNav } from "@synerg/react-components/dist/sfc-components";
import getMenu from "../routes/menu";
import {
  findBestPartialPathMatchInNestedArray,
  findInNestedArray,
} from "../utility/ObjectUtil";
import "./articles/previews/GuidesPrintView/WholeGuidePrintView/style.css";
import { useFetchProductsQuery, useFetchElasticSearchQuery } from "../store";
import { SdfAlert } from "@synerg/react-components";
import { buildQueryObj } from "../utility/FetchingUtils";

const encodeString = (string) => encodeURIComponent(string).replace(/%20/g, "+");

const getActiveItem = (allRoutes, location) => {
  const path = location.search
    ? location.pathname + location.search
    : location.pathname;

  const params = new URLSearchParams(location.search);
  const apiGuides = params.getAll("useCaseTags");
  const integrationGuides = params.getAll("mkplIntegrationCategory");

  if (apiGuides.length > 1) {
    return findInNestedArray(
      allRoutes,
      "pathname",
      "/guides/api-guides",
      false
    );
  } else if (apiGuides.length === 1) {
    return findInNestedArray(
      allRoutes,
      "pathname",
      `/guides/api-guides?useCaseTags=${encodeString(apiGuides[0])}`,
      false
    );
  } else if (integrationGuides.length > 1) {
    return findInNestedArray(
      allRoutes,
      "pathname",
      "/guides/adp-marketplace-integration-guides",
      false
    );
  } else if (integrationGuides.length === 1) {
    return findInNestedArray(
      allRoutes,
      "pathname",
      `/guides/adp-marketplace-integration-guides?mkplIntegrationCategory=${encodeString(integrationGuides[0])}`,
      false
    );
  }

  return (
    findInNestedArray(allRoutes, "pathname", path, false) ||
    findBestPartialPathMatchInNestedArray(allRoutes, "pathname", path, true)
  );
};

function TopNav() {
  const {
    data: productData,
    isLoading: isProductLoading,
    isError: isProductError,
  } = useFetchProductsQuery();
  const {
    data: elasticData,
    isLoading: isElasticLoading,
    isError: isElasticError,
  } = useFetchElasticSearchQuery(buildQueryObj({ index: "usecases" }));
  const [allRoutes, setAllRoutes] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [navReady, setNavReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isPrintView = Boolean(searchParams.get("printView"));
  const isLoading = isProductLoading || isElasticLoading;
  const isError = isProductError || isElasticError;

  useEffect(() => {
    if (!isProductLoading && !isElasticLoading) {
      const [menu] = getMenu(productData, elasticData);
      setAllRoutes(menu.appRoutes);
    }
  }, [isProductLoading, isElasticLoading]);

  useEffect(() => {
    if (allRoutes && navReady) {
      setActiveItem(getActiveItem(allRoutes, location));
    }
  }, [location, allRoutes, navReady]);

  return (
    !isPrintView &&
    allRoutes &&
    !isLoading && (
      <Fragment>
        {isError && (
          <SdfAlert closeable status="error" toast={true}>
            An error occurred when fetching all Product Integrations.
          </SdfAlert>
        )}
        <SfcShellTopNav
          id="nav"
          slot="nav"
          left-nav-type=""
          mega-menu-layout=""
          activeItem={activeItem}
          role="navigation"
          menuProvider={allRoutes}
          onSfcShellTopNavReady={() => setNavReady(true)}
          onSfcNavigate={(event) => {
            event.preventDefault();
            navigate(event.detail.pathname);
          }}
          data-testid="top-nav"
        />
      </Fragment>
    )
  );
}

export default TopNav;
