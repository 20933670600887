import { useSearchParams } from "react-router-dom";

import Header from "./Header";
import ApiLibrary from "./ApiLibrary";
import BuildingIntegrations from "./BuildingIntegrations";
import GetStarted from "./GetStarted";
import IntroducingApiCentral from "./IntroducingApiCentral";
import ExpertConsulting from "./ExpertConsulting";
import RecommendApiCentral from "./RecommendApiCentral";
import AvailableGuides from "./AvailableGuides";
import BuyNow from "./BuyNow";
import { guideItemListUs, guideItemListInt } from "./guideItemList";
import { FAQs } from "../shared/FAQs";
import { faqItemsUs, faqItemsInt } from "./faqItemList";
import Breadcrumb from "../Breadcrumb";
import PageLoading from "../shared/PageLoading";

const ClientIntegration = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const international = searchParams.get("international") || false;
  const setInternational = (value) =>
    setSearchParams((params) => {
      if (value) {
        params.set("international", value);
      } else {
        params.delete("international");
      }
      return params;
    });

  const breadcrumbPath = [
    { name: "Home", route: "/" },
    {
      name: "Getting Started / Client Integration Overview",
      route: "/getting-started/client-integration-overview",
    },
  ];

  const guideItemList = international ? guideItemListInt : guideItemListUs;
  const faqItems = international ? faqItemsInt : faqItemsUs;

  return (
    <PageLoading loadOnSearch>
      <div className="w-4/5 flex justify-start pl-10">
        <Breadcrumb path={breadcrumbPath} />
      </div>
      <BuyNow {...{ international }} />
      <div className="overflow-hidden flex flex-col items-center gap-12">
        <Header {...{ international, setInternational }} />
        <ApiLibrary {...{ international }} />
        <BuildingIntegrations {...{ international }} />
        <GetStarted {...{ international }} />
        {international ? (
          <IntroducingApiCentral />
        ) : (
          <>
            <ExpertConsulting />
            <RecommendApiCentral />
          </>
        )}
        <AvailableGuides {...{ guideItemList, international }} />
        <div className="min-w-full bg-action-default-50 relative">
          <FAQs {...{ faqItems }} />
        </div>
      </div>
    </PageLoading>
  );
};

export default ClientIntegration;
