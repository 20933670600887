import React, { useEffect, useState } from "react";
import get from "lodash/get";
import {
  SdfButton,
  SdfTooltip,
  SdfIcon,
  SdfIconButton,
  SdfAlert,
  SdfBox,
} from "@synerg/react-components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FeatureSteps from "./FeatureSteps";
import {
  useFetchElasticSearchByIdQuery,
  useUpdateMetricsMutation,
} from "../../../store";
import { buildQueryObj } from "../../../utility/FetchingUtils";
import { NotFound } from "../../NotFound";
import Breadcrumb from "../../../components/Breadcrumb";
import { closeAppBarSearch } from "../../../utility/searchUtil";
import { BusyPageIndicator } from "../../../components/BusyPageIndicator";
import "./UseCasePage.css";

const integrationFlowsStateHash = "#integration-flow";
const adpSolutionKeys = (solution) => {
  switch (solution) {
    case "Recruiting":
      return "Recruiting";
    case "Human Resources":
      return "Human-Resources";
    case "Time & Labor Management":
      return "Time-Labor-Management";
    case "Payroll Management":
      return "Payroll-Management";
    case "Benefit Management":
      return "Benefit-Management";
    case "Compliance Solutions":
      return "Compliance-Solutions";
    case "Compensation":
      return "Compensation";
    case "Engagement & Benefits":
      return "Engagement & Benefits";
    case "API & Data Connectors":
      return "API & Data Connectors";
    case "Finance":
      return "Finance";
    case "Learning Management":
      return "Learning Management";
    case "Performance":
      return "Performance";
    case "Productivity & Collaboration":
      return "Productivity & Collaboration";
    case "Recruitment & Onboarding":
      return "Recruitment & Onboarding";
    case "Time & Activities Management":
      return "Time & Activities Management";
    case "Travel & Expense Management":
      return "Travel & Expense Management";
    default:
      return "";
  }
};
const iconRegex = /^[a-z]+(-[a-z]+)*$/;

function UseCasePage({ id, searchBar, showBreadcrumb = true, useCasePreview = false }) {
  let source;
  const [showFeatureSteps, setShowFeatureSteps] = useState(false);
  const [selectedFeatureItem, setSelectedFeatureItem] = useState({});
  const { id: paramsId } = useParams();
  const location = useLocation();
  if (!id) {
    id = paramsId;
  }

  let { isSuccess, data, isFetching } = useFetchElasticSearchByIdQuery(
    buildQueryObj({
      index: useCasePreview ? "usecases-preview" : "usecases",
      id,
      sort: [{}],
    }),
    { skip: source }
  );

  let useCaseData;
  if (isSuccess) {
    useCaseData = data?.hits?.hits[0]?._source;
    source = source ?? useCaseData;
  }

  const section1 = get(source, "content.section1", "");
  const solutions = get(source, "content.section2.solutions", []);

  const navigate = useNavigate();
  const solutionTitle = get(source, "title", "");

  const breadcrumbPath = [
    { name: "Home", route: "/" },
    { name: "Solutions" },
    { name: solutionTitle },
  ];

  const [updateMetrics, results] = useUpdateMetricsMutation();

  useEffect(() => {
    updateMetrics({ id, type: "usecases" });
  }, []);

  const errorMsg = results.error ? (
    <>
      <SdfAlert closeable status="error">
        An error occurred when updating metrics for this document
      </SdfAlert>
    </>
  ) : (
    <></>
  );

  const updateFeatureFromHash = () => {
    const getHashWithoutIndex = location.hash.substring(
      0,
      location.hash.lastIndexOf("-")
    );
    if (getHashWithoutIndex === integrationFlowsStateHash) {
      const getIndex = location.hash.substring(
        location.hash.lastIndexOf("-") + 1
      );
      if (solutions[getIndex - 1] === undefined) {
        setShowFeatureSteps(false);
        setSelectedFeatureItem({});
      } else {
        setShowFeatureSteps(true);
        setSelectedFeatureItem(solutions[getIndex - 1]);
      }
    }
  };

  useEffect(() => {
    updateFeatureFromHash();
    if (showFeatureSteps && location.hash === "") {
      setShowFeatureSteps(false);
      setSelectedFeatureItem({});
    }
  }, [location]);

  useEffect(() => {
    updateFeatureFromHash();
  }, [source]);

  const onOpenHandler = (index, state) => {
    closeAppBarSearch();
    let path = useCasePreview
      ? `/api-usecases/usecases-preview/${id}`
      : `/solutions/usecases/${id}`;
    navigate({
      pathname: path,
      hash: `${state}-${index + 1}`,
    });
    setShowFeatureSteps(true);
    setSelectedFeatureItem(solutions[index]);
  };

  const onCloseHandler = () => {
    let path = useCasePreview
      ? `/api-usecases/usecases-preview/${id}`
      : `/solutions/usecases/${id}`;
    navigate(path);
    setShowFeatureSteps(false);
    setSelectedFeatureItem({});
  };

  const clickHandler = () => {
    const params = new URLSearchParams({
      filterQuery: "",
      product: "",
      useCaseTags: adpSolutionKeys(solutionTitle),
    });
    closeAppBarSearch();
    navigate({
      pathname: "/guides/api-guides",
      search: params.toString(),
      state: { filter: adpSolutionKeys(solutionTitle) },
    });
  };

  if (!isFetching && solutions?.length === 0) return <NotFound />;

  return isFetching ? (
    <BusyPageIndicator positionClass="absolute-center" />
  ) : (
    <div className="min-h-screen">
      <div className="w-11/12 m-auto">
        {showBreadcrumb && <Breadcrumb path={breadcrumbPath} />}
        {errorMsg}
      </div>
      <div className={`m-auto bg-action-default-50 rounded-lg${searchBar ? "" : " w-11/12"}`}>
        <div className={`flex flex-col justify-between gap-8${searchBar ? " px-4 py-8" : " p-8 lg:flex-row lg:gap-16"}`}>
          <div className="flex flex-col justify-between py-4">
            <div className="flex flex-col gap-4">
              <div className="m-0 text-heading-05">
                {source?.region} solutions
              </div>
              <h1 className="m-0 text-heading-07">{section1?.title}</h1>
              <p className="m-0 text-heading-03">{section1?.subtitle}</p>
            </div>
            <div className="pt-8">
              <SdfButton emphasis="primary" onclick={() => clickHandler()}>
                View Documentation
              </SdfButton>
            </div>
          </div>
          <img
            className="h-xs w-xs m-auto lg:m-0 lg:mr-16 object-contain"
            src={`${section1?.img}`}
            alt={section1?.title || "integration solution"}
          />
        </div>
      </div>
      <div className="m-auto pt-8 pb-16 w-11/12">
        <ul className={`list-none m-0 p-0 grid grid-cols-1${searchBar ? " px-4" : "pl-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"}`}>
          {solutions?.map((solution, index) => (
            <li className="flex flex-col">
              <div className="flex-grow flex-shrink-0">
                <SdfBox className="h-full" key={index} variant="bordered">
                  <div className="h-full flex flex-col justify-between gap-4">
                    <div className="flex flex-col gap-4">
                      <div className="relative flex items-center justify-center solution-image-container">
                        <div className="bg-action-help-700 w-full h-full solution-image-shape" />
                        <SdfIcon
                          icon={`${solution?.icon && iconRegex.test(solution?.icon) ? solution?.icon : "accessible"}`}
                          className="absolute text-4xl text-inverse-default m-auto w-full h-full"
                        />
                      </div>
                      <h2 className="m-0 text-heading-05">
                        {solution?.iconTitle}
                      </h2>
                      <p className="m-0 text-body-02">
                        {solution?.iconSubtitle}
                      </p>
                    </div>
                    <div>
                      {solution?.example?.adpProductExamples?.length > 0 && (
                        <SdfButton
                          onclick={() =>
                            onOpenHandler(index, integrationFlowsStateHash)
                          }
                        >
                          View integration flows
                        </SdfButton>
                      )}
                    </div>
                  </div>
                </SdfBox>
              </div>
              <div className="use-case-compatible">
                {solution?.example?.adpProductExamples?.length > 0 && (
                  <>
                    <SdfTooltip tooltipId="alert-info" className="">
                      <SdfIconButton slot="tooltip-target">
                        <SdfIcon icon="alert-info" className="mt-2"></SdfIcon>
                      </SdfIconButton>
                      {solution?.example?.adpProductExamples?.map(
                        (example, index) => {
                          return <div key={index}>{example?.sorName}</div>;
                        }
                      )}
                    </SdfTooltip>
                    <span className="ml-2 text-default ">
                      Compatible ADP Products
                    </span>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {showFeatureSteps && (
        <FeatureSteps
          handleOnHide={onCloseHandler}
          show={showFeatureSteps}
          selectedFeatureItem={selectedFeatureItem}
          useCaseInfo={section1}
        />
      )}
    </div>
  );
}

export default UseCasePage;