export const AVAILABLE_APIs = [
  [
    { fieldName: "person.legalName.givenName", exampleValue: "Anthony" },
    { fieldName: "person.legalName.middleName", exampleValue: "A" },
    { fieldName: "person.legalName.familyName1", exampleValue: "Albright" },
    { fieldName: "person.birthDate", exampleValue: "1988-10-30" },
    {
      fieldName: "person.governmentIDs.idValue (SSN)",
      exampleValue: "XXX-XX-3761",
    },
    {
      fieldName: "person.legalAddress.lineOne",
      exampleValue: "123 Main Street",
    },
    { fieldName: "person.legalAddress.lineTwo", exampleValue: "Apt 4B" },
    { fieldName: "person.legalAddress.cityName", exampleValue: "Roseland" },
    {
      fieldName: "person.legalAddress.countrySubdivisionLevel1.shortName",
      exampleValue: "New Jersey",
    },
    {
      fieldName: "communication.mobiles.formattedNumber",
      exampleValue: "202-555-5555 (cell)",
    },
  ],
  [
    { fieldName: "person.genderCode.codeValue", exampleValue: "M" },
    { fieldName: "person.genderCode.shortName", exampleValue: "Male" },
    { fieldName: "person.maritalStatusCode.codeValue", exampleValue: "W" },
    {
      fieldName: "person.maritalStatusCode.shortName",
      exampleValue: "Widowed",
    },
    {
      fieldName: "person.ethnicityCode.longName",
      exampleValue: "Not Hispanic or Latino",
    },
    { fieldName: "person.raceCode.longName", exampleValue: "White" },
    { fieldName: "person.raceCode.shortName", exampleValue: "Wh" },
    { fieldName: "person.tobaccoUserIndicator", exampleValue: "False" },
    {
      fieldName: "person.disabledIndicator",
      exampleValue: "False",
    },
    {
      fieldName: "person.SocialInsurancePrograms.nameCode.shortName",
      exampleValue: "Medicare",
    },
  ],
  [
    { fieldName: "workAssignments.hireDate", exampleValue: "01/01/2015" },
    { fieldName: "workerStatus.StatusCode.codeValue", exampleValue: "Active" },
    {
      fieldName: "workAssignments.actualStartDate",
      exampleValue: "01/08/2015",
    },
    {
      fieldName: "workAssignments.jobCode.shortName",
      exampleValue: "Part-time",
    },
    { fieldName: "workAssignments.jobCode.codeValue", exampleValue: "PT" },
    {
      fieldName: "workAssignments.jobTitle",
      exampleValue: "Billing Coordinator",
    },
    {
      fieldName: "workAssignments.homeOrganizationalUnits.typeCode.shortName",
      exampleValue: "Business Unit",
    },
    {
      fieldName: "workAssignments.homeOrganizationalUnits.nameCode.shortName",
      exampleValue: "Accounting",
    },
    {
      fieldName: "workAssignments.itemID",
      exampleValue: "00854955E84",
    },
    {
      fieldName: "workerDates.OriginalHireDate",
      exampleValue: "05/05/2010",
    },
  ],
  [
    { fieldName: "payCycleCode.shortName", exampleValue: "Weekly" },
    { fieldName: "standardPayPeriodHours", exampleValue: "40" },
    { fieldName: "payPeriodRateAmount.amountValue", exampleValue: "1233" },
    { fieldName: "payPeriodRateAmount.currencyCode", exampleValue: "USD" },
    { fieldName: "payPeriodRateAmount.nameCode", exampleValue: "Salary" },
    { fieldName: "payrollGroupCode", exampleValue: "94N" },
    { fieldName: "payrollFileNumber", exampleValue: "002344" },
    {
      fieldName: "payrollScheduleGroupID",
      exampleValue: "Append 1 to period end date ",
    },
    {
      fieldName: "managementPositionIndicator",
      exampleValue: "False",
    },
    {
      fieldName: "baseReunumeration.effectiveDate",
      exampleValue: "2024-07-12",
    },
  ],
];

export const API_TITLE = [
  "Personally Identifiable Information (PII)",
  "Demographic Information",
  "Employment Information",
  "Compensation Information",
];

export const INTEGRATION_OPTIONS = [
  {
    label: "Build integrations with my company's ADP data",
    value: "0",
  },
  {
    label: "Sell my integrated solution to ADP clients",
    value: "1",
  },
];

export const integrationFoundation = [
  {
    title: "Product guides",
    desc: "Step-by-step implementation guides to help you build integrations to a specific ADP product",
    url: "/guides/api-guides",
  },
  {
    title: "Marketplace guides",
    url: "/guides/adp-marketplace-integration-guides",
    desc: "Learn how to setup your listing on our digital storefront and how ADP authentication and authorization models work",
  },
  {
    title: "Key Concepts",
    desc: "Learn about authentication, single-sign on and our digital storefront platform",
    url: "/getting-started/key-concepts",
  },
];

export const USE_CASE_ROLES = [
  { label: "ADP clients", value: "ADP clients" },
  {
    label: "ADP Marketplace partners",
    value: "ADP Marketplace partners",
  },
];

export const useCaseData= [{
  title: "Integrating as an ADP client*",
  description: <>*Process varies for international clients. Learn more about <a target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/getting-started/client-integration-overview?international=true`}>integrating outside North America.</a></>,
  linkId: "getStarted1",
  linkText: "Learn what’s available to ADP clients"
}
, {
  title: "Integrating as an ADP Marketplace Partner*",
  description: <>*Process varies for international partners. Learn more about <a target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/getting-started/partner-integration-overview?international=true`}>integrating outside North America.</a></>,
  linkId: "getStarted2",
  linkText: "Learn what’s available to ADP Marketplace partners"
}]

export const TIMELINE_1_ITEMS = [
  {
    header: "Purchase",
    message: "Purchase ADP API Central for your company.",
    illustrationName: "shopping-basket",
    details: "",
  },
  {
    header: "Sign in",
    message:
      "Use your existing ADP credentials to sign in to the API Central application.",
    illustrationName: "browser-locked",
    details: "",
  },
  {
    header: "Onboard",
    message: "Add internal and external team members and assign user roles.",
    illustrationName: "gears",
    details: "",
  },
  {
    header: "Create project",
    message:
      "Start with prepackaged API use cases and add more APIs as you need them.",
    illustrationName: "clipboard-gear",
    details: "",
  },
  {
    header: "Build",
    message:
      "Develop your API integration using in-house talent or a third-party integrator.",
    illustrationName: "laptop-code",
    details: "",
  },
  {
    header: "Launch",
    message: "Push your code live into a production environment.",
    illustrationName: "high-five",
    details: "",
  },
];

export const TIMELINE_2_ITEMS = [
  {
    header: "Engagement",
    illustrationName: "paperless",
    message: [
      "Application submission",
      "Application review and approval based on ADP criteria",
      "Security and legal review",
      "Sandbox agreement",
    ],
    details:
      "\nAfter you submit your application, the ADP Marketplace team will review it and contact you if your solution may be a good fit.\n\nGiven the volume of applications we receive, please allow time for our team to review and reply.\n\nIf you are approved to move forward after an initial conversation with our team about your product, business model, and vision for your integration, you will complete security and legal reviews, then sign a sandbox agreement.",
  },
  {
    header: "Project planning",
    illustrationName: "clipboard-gear",
    message: [
      "Sandbox access",
      "Pricing structure",
      "Provide milestones",
      "Project plan creation",
      "Execution of developer agreement",
      "Kickoff call",
    ],
    details: `\nAfter you sign the sandbox agreement, you can begin scoping your project. During this phase, you’ll provide the ADP team with milestones and your project plan. You’ll also work with your business development team to determine your solution’s pricing structure\n\nThen, your team will sign our developer participation agreement, which outlines the terms of the partnership, and have a kickoff call to meet the broader ADP Marketplace team.`,
  },
  {
    header: "Integrations",
    illustrationName: "laptop-code",
    message: [
      "Build three integrations:",
      "Data integration",
      "ADP Marketplace storefront (e‑commerce)",
      "Single sign‑on (SSO)",
    ],
    details:
      "\nEach ADP Marketplace partner is required to build three separate integrations: one to share data between your solution and ADP, one to enable e‑commerce purchasing through ADP Marketplace, and one to enable single sign-on with ADP credentials\n\nAs you work on your integrations, your marketing team will write your app listing marketing content, which will help introduce ADP clients and sales teams to your solution.",
  },
  {
    header: "Certifications",
    illustrationName: "shield",
    message: [
      "Demo development projects",
      "App security certification and remediation",
    ],
    details:
      "\nAfter you build your integrations, you’ll demo your project to your ADP Marketplace technical advisor to show you’ve completed your technical requirements, including your API use cases, order subscription processing, single sign-on, and more\n\nYour solution will also complete mandatory data security and privacy review by ADP’s Global Security Organization (GSO). The review, testing and issue resolution process helps assure ADP and our clients that data is processed, governed, and transmitted securely.",
  },
  {
    header: "Go live",
    illustrationName: "high-five",
    message: [
      "Production project and app creation",
      "Pricing approval",
      "Final demo to ADP",
      "App listing approval",
      "App publishing",
    ],
    details:
      "\nOnce you’ve passed security review, you’ll demo your project to your technical advisor again in a production environment to ensure your integrations are working as expected\n\nAfter you’ve configured your product pricing, your complete purchase flow works, and the ADP Marketplace team has approved your app listing content, you’ll be ready to go live and start selling to ADP clients.",
  },
];

export const IN_TIMELINE_ITEMS = [
  {
    header: "Engagement",
    illustrationName: "paperless",
    message: [
      "Discovery form submission",
      "Application review and approval based on ADP criteria",
      "Security and legal review",
      "Partner agreement",
    ],
    details:
      <><br />If you’re interested in becoming an ADP Marketplace international partner, please first complete <a target="_blank" rel="noopener noreferrer" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOALyNgwiG-W2RHkiPVGZCLXJZUOTE1TkIzNDYxVzUzWlFTRFEwT1JRS0s5RC4u&route=shorturl">our discovery form</a> and our team will contact you if your solution may be a good fit.<br /><br />If you are approved to move forward, you will complete security and compliance reviews, then sign an ADP Marketplace international partner agreement (also applicable to existing ADP Marketplace partners in North America).</>,
  },
  {
    header: "Project planning",
    illustrationName: "clipboard-gear",
    message: [
      "Execution of partner agreement",
      "Kickoff call",
      "Create and share project plan",
      "Sandbox access",
    ],
    details: `\nAfter you sign the ADP Marketplace international partner agreement, you can begin scoping your project.\n\nDuring this phase, you’ll work with the ADP Marketplace team to set milestones and the project plan. Then, you’ll be ready to begin your integration.`,
  },
  {
    header: "Integrations",
    illustrationName: "laptop-code",
    message: ["Develop integration​"],
    details:
      "\nEach ADP Marketplace partner is required to build an integration between their solution(s) and the ADP system(s).\n\nAs you work on your integration(s), you’ll begin preparing your app listing marketing content, which will help introduce ADP clients and sales teams to your solution(s).",
  },
  {
    header: "Certifications",
    illustrationName: "shield",
    message: [
      "Demo development projects",
      "App security certification and remediation",
    ],
    details:
      "\nAfter you build your integration(s), you’ll demo your project to your ADP Marketplace key stakeholders.\n\nYour solution will also complete mandatory data security and privacy review by ADP’s Global Security Organization (GSO). The review, testing, and issue resolution process helps assure ADP and our clients that data is processed, governed, and transmitted securely.",
  },
  {
    header: "Go live",
    illustrationName: "high-five",
    message: [
      "App listing creation",
      "Final demo to ADP",
      "Pilot client",
      "Sales enablement",
      "General release",
    ],
    details:
      "\nOnce you’ve passed security review, you’ll demo your project to the ADP Marketplace integration team in a production environment to ensure it is working as expected.\n\nOnce the ADP Marketplace team has approved your app listing content, you’ll be ready to pilot your solution with ADP clients. After the pilot phase, you’ll be approved for general release to clients.",
  },
];


export const FAQ_ITEMS = [
  {
    header: "Who can access ADP APIs?",
    detail: `Access to ADP APIs is available to ADP clients, ADP Marketplace partners, and third-party integrators working on behalf of clients and partners. Clients can gain API access (and share that access with integrators) by purchasing <a target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/getting-started/client-integration-overview">ADP API Central</a>, and then share access with third-party developers as needed. <a target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/getting-started/partner-integration-overview">ADP Marketplace partners</a>, who sell their third-party solutions on our HR storefront, must be accepted into our partner program and complete a sandbox agreement before accessing APIs.`,
  },
  {
    header: "What do I need to do in order to start calling ADP APIs?",
    detail: `Whether you’re an ADP client or an ADP Marketplace partner, you’ll need to log into your respective API platform (API Central for clients or the ADP Marketplace Partner Self-Service Tool for partners) and generate a mutual SSL certificate. Then, you’ll start a new project, which will grant you client credentials authorization. After that, you can start calling APIs.`,
  },
  {
    header: "What type of data is accessible through ADP APIs?",
    detail: `Developers have access to a variety of ADP APIs that span the employee life cycle including HR, time and labor management, payroll, recruiting, benefits, and more. Available APIs vary by ADP product, such as ADP Workforce Now®, RUN Powered by ADP®, ADP Vantage HCM®, ADP Lyric, ADP DECIDIUM®, ADP iHCM®, and others. <a target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/apis/api-explorer">Explore all APIs here</a>.`,
  },
  {
    header:
      "Can ADP build integrations for my organization after we purchase ADP API Central?",
    detail: `ADP does not offer integration development services directly; however, experienced ADP Marketplace partners are available to build and manage your custom integrations. Our team can recommend partners if you complete our Integration Needs Discovery Form <a target="_blank" rel="noopener noreferrer" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL7-lT9WD1B9DnG_-de4DYDxUMEk3VTVBS1Y1RVpCN0IyNkRJM0FJWU9BSS4u">for companies using U.S.-based ADP systems</a> or <a target="_blank" rel="noopener noreferrer" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL5N1d5hK5v1MsggoA49vwMZUOVhWVlpWTE9HRUFYRTNGMjg4NzJOS1NUVS4u">for companies using international ADP systems</a>.`,
  },
  {
    header:
      "Where can I learn more about how to purchase ADP API Central for my organization?",
    detail: `If your company uses an ADP system based in the U.S., you can<a target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/getting-started/client-integration-overview"> learn more about ADP API Central here</a>. If your company uses an international ADP system, <a target="_blank" rel="noopener noreferrer" href="${process.env.PUBLIC_URL}/getting-started/client-integration-overview?international=true">learn more about ADP API Central here</a>.`,
  },
];

export const ITEMS = [
  { label: "North America", value: 0 },
  { label: "International", value: 1 },
];

export const NA_DESCRIPTION = "The path to becoming an ADP Marketplace partner";
export const IN_DESCRIPTION =
  "The path to becoming an ADP Marketplace partner ";

  export const NA_SUBTITLE = "Interested in selling your solution on ADP Marketplace? Here’s what you can expect if you are accepted into our exclusive program.";
export const IN_SUBTITLE=
  "Interested in selling your solution on ADP Marketplace in our international storefront? Here’s what you can expect if you are accepted into our selective program. ";

export const NA_readinessValues = [
  "App listing content and pricing configuration",
  "Finance and billing",
  "Listing terms of service",
  "Operational readiness",
];
export const IN_readinessValues = [
  "Sales readiness preparation",
  "Operational readiness",
  "App listing content",
  "Finance and billing",
  "Pilot client identification",
];

export const timelineData = [
  {
    timelineItems: TIMELINE_2_ITEMS,
    description: NA_DESCRIPTION,
    readinessValues: NA_readinessValues,
    subTiltle: NA_SUBTITLE
  },
  {
    timelineItems: IN_TIMELINE_ITEMS,
    description: IN_DESCRIPTION,
    readinessValues: IN_readinessValues,
    subTiltle: IN_SUBTITLE
  },
];
