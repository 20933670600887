import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SdfButton } from "@synerg/react-components";

import sortAlphabeticalNoSymbol from "../../utility/sortAlphabeticalNoSymbol";
import "./FeaturedSolutions.css";

const defaultDescription =
  "Lorem ipsum odor amet, consectetuer adipiscing elit. Suspendisse in tempor dolor risus neque dignissim dignissim.";

/**
 * @typedef {Array<{
 *    title: string,
 *    description?: string,
 *    pathName: string,
 *    isPopular: boolean,
 *    internationalOffering: boolean,
 *    northAmericaOffering: boolean
 *  }>} solutionList
 * @param {{ gridTitle: string, list: solutionList, row: number }} props
 */
const SolutionGrid = ({ gridTitle, list, column }) => {
  const navigate = useNavigate();

  return (
    <>
      <h3 className={`m-0 text-heading-05 .row-start-1 col-start-${column}`}>{gridTitle}</h3>
      {list.map(({ title, description, pathName }, index) => (
        <div
          key={`featured-solution-${gridTitle}-${index}`}
          className={`p-4 relative flex flex-col gap-4 col-start-${column} featured-solution-item${index === list.length - 1 ? " grid-end" : ""}`}
          style={{ "--grid-row": index + 2 }}
        >
          <h4 className="m-0 text-heading-04">{title}</h4>
          <p className="m-0 text-body-02 flex-grow">
            {description || defaultDescription}
          </p>
          <SdfButton variant="text" onClick={() => navigate(pathName)}>
            View APIs
          </SdfButton>
        </div>
      ))}
    </>
  );
};

/**
 * @param {{ solutionList: solutionList }} props
 */
const FeaturedSolutions = ({ solutionList }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isLarge = windowWidth >= 1024;

  const northAmericaProps = {
    column: 1,
    gridTitle: "North American Solutions",
    list: solutionList.filter(
      (solution) => solution.northAmericaOffering && solution.isPopular
    ),
  };

  const internationalProps = {
    column: isLarge ? 2 : 1,
    gridTitle: "International Solutions",
    list: solutionList
      .filter(
        (solution) => solution.internationalOffering && solution.isPopular
      )
      .sort(sortAlphabeticalNoSymbol),
  };

  return (
    <div className="w-11/12 md:w-4/5">
      <h2 className="m-0 pt-4 pb-2 text-heading-06">
        Featured Solutions for Integration
      </h2>
      {isLarge ? (
        <div className="pt-4 grid grid-flow-col grid-cols-2 auto-rows-fr gap-x-8 gap-y-4 featured-solution-grid">
          <SolutionGrid {...northAmericaProps} />
          <SolutionGrid {...internationalProps} />
        </div>
      ) : (
        <>
          <div className="pt-4 grid grid-flow-col grid-cols-1 auto-rows-fr gap-x-8 gap-y-4 featured-solution-grid">
            <SolutionGrid {...northAmericaProps} />
          </div>
          <div className="pt-8 grid grid-flow-col grid-cols-1 auto-rows-fr gap-x-8 gap-y-4 featured-solution-grid">
            <SolutionGrid {...internationalProps} />
          </div>
        </>
      )}
      
    </div>
  );
};

export default FeaturedSolutions;
