import { Link } from "react-router-dom";
export function HeroImageList({ alt, linkList, partnerLanding, ...rest }) {
  return (
      <ul className="list-none">
      {linkList.map((item, index) => (
        <li className="m-4" key={`hero-link-${index}`}>
          <label for={item.title} className="block text-heading-02">{item.title}</label>
          {" "}
            <Link
              to={item.pathName}
              className='text-heading-01 no-underline whitespace-nowrap'
              id={item.title}
            >
              {item.linkText}
            </Link>
        </li>
      ))}
      </ul>
  );
}
