/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useRef, useState} from "react";
import {SdfLink} from "@synerg/react-components";
import {scrollToElement} from "../../utility/DOMUtils";
import {RateArticle} from "./RateArticle";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ARTICLES_BORDER_LEFT, NO_PRINT_CLASS} from "../../utility/constants";
import {removeExtraSpacesFromString} from "../../utility/stringUtil";

const TOP_RIGHT = 'max-w-62 min-w-62';
const CONTENT = '-ml-px cursor-pointer no-underline hover:underline text-action-primary border-solid rounded-none p-3 text-md border-r-0 border-b-0 border-t-0 border-4 -mr-3 py-3 border-transparent'
const SELECTED_CONTENT = CONTENT.replace('border-transparent', '')

const filterByTag = (arr, tagName) => arr.filter((tag) => tag.tagName === tagName)
const getArticleContents = (content) => {
    let contents = content?.querySelectorAll('*')
    if (!contents) return [];

    return Array.from(contents)
        .filter((tag) => (tag.innerText && tag.innerText.trim() !== ''))
        .map((tag, index) => {
            tag.id = index
            return tag
        })
    || [];
}

const findClosestH3 = (index, elements) => {
    if(index === 0) return;

    for (let i = index - 1; i > 0; i--) {
        const elm = elements[i]

        if(elm.tagName === 'H3') return elm
    }
}

const generateElementToParentDict = (elements) => {
    // please note it is not parent in the sense of html, but the content header that each not header element "belongs"
    return elements
        .reduce((acc, elm, index) => ({
            ...acc,
            [elm.id]: {
                parent: findClosestH3(index, elements),
                elm
            }
        }), {})
}

function generateUrlId(str) {
    return str?.replaceAll(' ', '-').toLowerCase()
}

export function ContentsMenu({article, isLgOrBigger, isGuide=false, className=''}) {
    if (!isLgOrBigger) return;

    const pageParams = useParams();
    const location = useLocation()
    const navigate = useNavigate();
    const [renderContentsMenu, setRenderContentsMenu] = useState(false)
    const [renderRate, setRenderRate] = useState(false)
    const content = document.getElementById('doc-preview')
    const contentsElementsArray = getArticleContents(content)
    const h3s = filterByTag(contentsElementsArray, 'H3')
    const highlightedRef = useRef()
    //const [highlighted, setHighlighted] = useState()
    const elementsToContentsDict = generateElementToParentDict(contentsElementsArray)
    const scrollOffsetTopMargin = isGuide ? 5 : 84

    function getInitialHighlighted() {
        if(!location.hash) return h3s[0]

        return h3s.find((elm) => location.hash.slice(1) === generateUrlId(elm.innerText));
    }

    function updateLocation(str) {
        location.hash = str ? `#${str}` : ''
        navigate(location.hash)
    }

    function customSetHighlighted(element) {
        if(!element) return

        //setHighlighted(element)
        highlightedRef.current = element

        updateLocation(
            generateUrlId(element.innerText)
        )
    }

    function updateInitialHighlighted(element) {
        if(!element) return;

        customSetHighlighted(element)
        const currentUrlId = generateUrlId(highlightedRef.current?.innerText);

        if(currentUrlId === generateUrlId(h3s[0]?.innerText)) {
            updateLocation()
            return;
        }


        highlightedRef.current.urlId = currentUrlId
        updateLocation(highlightedRef.current?.urlId)
        scrollToElement(highlightedRef.current, scrollOffsetTopMargin, 'instant')
    }

    function updateMarginBottom() {
        if(!h3s?.length) return;

        const lastH3 = h3s[h3s.length - 1]
        const lastH3Id = parseInt(lastH3.id)
        let noPrintElements = 0;
        let lastBelowLastH3;
        Array.from(document.getElementsByClassName(NO_PRINT_CLASS) || [])
            .forEach((elm) => {
                if(elm.offsetHeight > 500) return
                noPrintElements += elm.offsetHeight
            }
        )
        contentsElementsArray.forEach((elm) => {
            if(lastH3Id > parseInt(elm.id)) return
            lastBelowLastH3 = elm
        })
        const lastH3Rect = lastH3.getBoundingClientRect();
        const belowLastH3Rect = lastBelowLastH3.getBoundingClientRect();

        let sum =
            // window
            window.innerHeight
            // header and footer
            - noPrintElements
            // distance between h3 and last element
            - (belowLastH3Rect.bottom - lastH3Rect.bottom)

        if(!lastH3?.parentElement?.parentElement.style) return
        sum = sum + (isGuide ? -66 : 20)
        lastH3.parentElement.style.marginBottom =  (sum <= 0 || !lastH3?.parentElement?.style) ? '0' : sum + 'px'

    }

    useEffect(() => {
        if(location.key !== 'default') return;
        const initialHighlighted = getInitialHighlighted()

        updateInitialHighlighted(initialHighlighted)

        scrollToElement(initialHighlighted, scrollOffsetTopMargin, 'instant')
    }, [location])

    useEffect(() => {
        if(!h3s?.length || (highlightedRef.current && h3s.find((h3) => h3?.isSameNode(highlightedRef.current)))) return;

        updateMarginBottom()
        updateInitialHighlighted(
            getInitialHighlighted()
        )
    }, [article])

    useEffect(() => {
        setRenderContentsMenu(true)
        setRenderRate(!article.isGuide || (article.isGuide && pageParams.chapterId))
    }, [])
    useEffect(() => {
        const handleContentsHighlighting = () => {
            let filtered = contentsElementsArray.filter((elm) => {
                 const rect = elm.getBoundingClientRect()
                  return (
                    rect.left >= 0 &&
                    rect.top >= 46 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                  )
            })

            if(!(filtered && filtered.length > 0)) return

            const filteredId = parseInt(filtered[0].id);
            const highlightedId = parseInt(highlightedRef.current?.id)

            if(
                filtered[0].tagName === 'H3' &&
                filteredId !== highlightedId
            ) {
                customSetHighlighted(filtered[0])
            } else if(
                highlightedRef.current &&
                filteredId < highlightedId
            ) {
                customSetHighlighted(elementsToContentsDict[highlightedRef.current?.id]?.parent)
            }
        }
        document.addEventListener('scroll', handleContentsHighlighting)
        updateMarginBottom()
        return () => {
            document.removeEventListener('scroll', handleContentsHighlighting)
        }
    }, [contentsElementsArray])

    function handleContentMenuByClick(element) {
        scrollToElement(element, scrollOffsetTopMargin)

        setTimeout(() => {
            if (highlightedRef?.current?.id !== element.id) {
                customSetHighlighted(element)
            }
        }, 800)
    }

    return (
        <aside>
            <div className={`mr-4 sticky overflow-y-auto overflow-x-hidden ${className}`} style={{top: '6rem'}}>
                {
                    renderRate &&
                    <div className={`${TOP_RIGHT} mr-10 -mt-8 mb-8`}>
                        <RateArticle article={article}/>
                    </div>
                }
                {
                    renderContentsMenu &&
                    h3s.length > 0 ? (
                        <div id="toc" className={`w-52 ml-12 pl-1 mt-1 ${TOP_RIGHT}`}>
                            <div className="text-heading-01 font-bold text-tertiary pb-4">CONTENTS</div>
                                <div className={`flex flex-col text-left -ml-px ml-2 ${ARTICLES_BORDER_LEFT}`}>
                                    {
                                        h3s.map((item) => (
                                            <SdfLink
                                                key={item.id}
                                                id={item.id}
                                                className={highlightedRef.current?.id === item.id ? SELECTED_CONTENT : CONTENT}
                                                onClick={() => handleContentMenuByClick(item)}
                                            >
                                                <span>
                                                    {removeExtraSpacesFromString(item.innerText)}
                                                </span>
                                            </SdfLink>
                                        ))
                                    }
                                </div>
                        </div>
                    ) :
                    <div className="md:w-52 ml-16"></div>
                }
            </div>
        </aside>
    )
}