import { useLocation, useNavigate } from "react-router-dom";
import { SdfButton } from "@synerg/react-components";

import { getStartedId } from "./GetStarted";

/**
 * @param {{ international: boolean }} props
 */
const ApiLibrary = ({ international }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const popularProps = {
    onClick: () => navigate(`${search ? search : ""}#${getStartedId}`),
  };

  const explorerUrl = international ? "hcm-offrg-dge" : "hcm-offrg-wfn";

  const browseProps = {
    emphasis: "primary",
    onClick: () => navigate(`/apis/api-explorer/${explorerUrl}`),
  };

  return (
    <div className="bg-action-primary-900 rounded-lg w-11/12 md:w-4/5">
      <div className="flex flex-col xl:flex-row px-12 md:pl-24 md:pr-12 py-12 gap-8">
        <div>
          <h2 className="m-0 text-heading-01 text-brand-secondary uppercase">
            API Library
          </h2>
          <p className="m-0 text-heading-06 text-inverse-default">
            Explore APIs to access your workforce data, connect systems, and
            more.
          </p>
        </div>
        <div className="flex flex-col md:flex-row flex-shrink-0 gap-8">
          <SdfButton {...popularProps}>View popular API use cases</SdfButton>
          <SdfButton {...browseProps}>
            <div className="px-4">Browse APIs</div>
          </SdfButton>
        </div>
      </div>
    </div>
  );
};

export default ApiLibrary;
