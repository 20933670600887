const faqItemsUs = [
  {
    header:
      "Where can I get more guidance on using ADP APIs and the best way to integrate with our ADP system?",
    detail: `The ADP Professional Services team can recommend integration options, including using ADP APIs and ADP API Central. Complete our <a target="_blank" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL7-lT9WD1B9DnG_-de4DYDxUMEk3VTVBS1Y1RVpCN0IyNkRJM0FJWU9BSS4u">Integration Needs Discovery Form</a>, and a team member will contact you to schedule a free consultation and recommendation.`,
  },
  {
    header: `Which ADP systems are supported by ADP® API Central?`,
    detail: `
    ADP API Central is an add-on subscription available for the following ADP systems: <br />
    <br />

    &nbsp;&nbsp; &#8226; &nbsp; ADP Workforce Now® <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Workforce Now® Next Generation <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Vantage HCM® <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Enterprise HR® (V6) <br />
    
    &nbsp;&nbsp; &#8226; &nbsp; ADP Lyric <br />
    <br />
    Available APIs vary by platform; you can explore all APIs here: <br /> <a class="break-all" target="_blank" href="${process.env.PUBLIC_URL}/apis/api-explorer">https://developers.adp.com/apis/api-explorer.</a>
    `,
  },
  {
    header: `How much does ADP® API Central cost?`,
    detail: `
    ADP® API Central is priced per employee per month. To request a price quote or purchase online, visit the app listings on ADP Marketplace: <br />
    <br />
    &nbsp;&nbsp; &#8226; &nbsp; <a target="_blank" href="https://apps.adp.com/en-US/apps/410612">ADP® API Central for ADP Workforce Now® </a><br />
    &nbsp;&nbsp; &#8226; &nbsp; <a target="_blank" href="https://apps.adp.com/en-US/apps/419897">ADP® API Central for ADP Vantage HCM®, ADP Enterprise HR® and ADP Lyric</a>
    `,
  },
  {
    header: `How do ADP® API Central clients get support for the product?`,
    detail: `
    There are multiple ways API Central clients can get integration support: <br />

    <br />
    <b>ADP API Central ticketed support:</b> You can file support tickets from within the API Central portal and our team will respond promptly to help keep your project on track. <br />
    <br />
    <b>ADP API Central consulting support:</b> ADP API specialists are available for an additional fee to guide you through your development efforts including API selection and best practices to drive your project to completion quickly. <br />
    `,
  },
  {
    header: "Can ADP build integrations for my organization after we purchase ADP® API Central?",
    detail: `
    ADP does not offer integration development services directly, however, experienced ADP Marketplace partners are available to build and manage your custom integrations. Our team can recommend partners if you complete our <a target="_blank" href="https://forms.office.com/pages/responsepage.aspx?id=gIQsTPDTW0i3UIB_9pOAL7-lT9WD1B9DnG_-de4DYDxUMEk3VTVBS1Y1RVpCN0IyNkRJM0FJWU9BSS4u">Integration Needs Discovery Form.</a>
    `,
  },
];

const faqItemsInt = [
  {
    header:
      "Who can access ADP APIs?",
    detail: `Access to ADP APIs is available to ADP clients, ADP Marketplace partners, and third-party integrators working on behalf of clients and partners. Clients can gain API access (and share that access with integrators) by purchasing ADP API Central. <a target="_blank" href="${process.env.PUBLIC_URL}/getting-started/partner-integration-overview?international=true">ADP Marketplace partners</a>, who sell their third-party solutions on our HR storefront, must be accepted into our partner program and complete a sandbox agreement before accessing APIs.`,
  },
  {
    header: "What is the typical timeline for integrating with ADP APIs?",
    detail: `ADP® API Central is a self-service product. Timelines depend on your organization’s needs, the complexity of the potential integration, and your current setup, as well as whether you plan to build the integration internally or outsource it to a third-party integrator. Outside of the United States, access to the ADP® API library itself is granted by the ADP Marketplace team upon contract signature.`,
  },
  {
    header: "Which ADP systems are supported by ADP API Central?",
    detail: `Available APIs vary by platform. You can explore API availability per platform here: <a class="break-all" target="_blank" href="${process.env.PUBLIC_URL}/apis/api-explorer">https://developers.adp.com/apis/api-explorer</a>.`,
  },
  {
    header: "How do ADP API Central clients get support for the product?",
    detail: `International ADP API Central clients can use our eService ticketing system to get dedicated support from ADP specialists. Specialists can answer specific API questions, provide support and guidance during implementation, and share best practices to help drive your project to completion.`,
  },
  {
    header: "Can ADP build integrations for my organization after we purchase ADP API Central?",
    detail: `ADP does not offer integration development services directly; however, experienced ADP Marketplace partners are available to build and manage integrations. Our team can recommend partners depending on the needs of your integration.`,
  },
];

export { faqItemsUs, faqItemsInt };