import React from "react";
import LinkWithIcon from "./LinkWithIcon";
import { InternalLink } from "./InternalLink";
import packagejson from "../../package.json";

import "./articles/previews/GuidesPrintView/WholeGuidePrintView/style.css";

function Footer() {
  const year = new Date().getFullYear();
  
  return (
    <footer className="no-print flex flex-col-reverse flex-end">
      <div
        className="flex flex-col lg:flex-row gap-10 justify-between border-solid border-0 border-light border-t-2 pt-8 pb-12 px-20"
        data-testid="footer"
      >
        <div className="flex gap-10 flex-wrap justify-between">
          <LinkWithIcon
            icon="external-link"
            external
            href="https://adp.com/privacy.aspx"
            id="privacy"
          >
            Privacy
          </LinkWithIcon>
          <LinkWithIcon
            icon="external-link"
            external
            href="https://adp.com/legal.aspx"
            id="terms"
          >
            Terms
          </LinkWithIcon>
          <LinkWithIcon
            icon="external-link"
            external
            href="https://apps.adp.com"
            id="apps"
          >
            Apps
          </LinkWithIcon>
          <LinkWithIcon
            icon="external-link"
            external
            href="https://partners.adp.com"
            id="partner"
          >
            Partner
          </LinkWithIcon>
          <InternalLink path="/support/help-center" id="helpCenter">
            Help Center
          </InternalLink>
        </div>
        <div
          title={"Application Version: " + packagejson.version}
          className="font-normal text-sm"
          id="copyright"
        >
          Copyright © {year} ADP, Inc.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
