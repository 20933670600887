import React, { useState } from "react";
import { PartnerSection } from "../components/partner-integration/PartnerSection";
import { MarketplaceSection } from "../components/partner-integration/MarketplaceSection";
import { ClientSection } from "../components/partner-integration/ClientSection";
import { ITEMS } from "../components/welcome-page/utility/constants";
import AvailableGuides from "../components/ClientIntegration/AvailableGuides";
import { FAQs } from "../components/shared/FAQs";
import Breadcrumb from "../components/Breadcrumb";
import PageLoading from "../components/shared/PageLoading";
import { SdfSegmentedControl } from "@synerg/react-components";
import {
  IN_FAQ_ITEMS,
  IN_GUIDE_ITEM_LIST,
  NA_FAQ_ITEMS,
  NA_GUIDE_ITEM_LIST,
} from "../components/partner-integration/utility/constants";
import { useSearchParams } from "react-router-dom";

/**
 * region:
 * North America - 0
 * International - 1
 **/
export function PartnerIntegrationPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [region, setRegion] = useState(
    searchParams.has("international") ? 1 : 0
  );
  const [loading, setLoading] = useState(false);

  const breadcrumbPath = [
    { name: "Home", route: "/" },
    {
      name: "Getting Started / Partner Integration Overview",
      route: "/getting-started/partner-integration-overview",
    },
  ];
  const REGION_DATA = {
    0: {
      faqItems: NA_FAQ_ITEMS,
      guideItemList: NA_GUIDE_ITEM_LIST,
    },
    1: {
      faqItems: IN_FAQ_ITEMS,
      guideItemList: IN_GUIDE_ITEM_LIST,
    },
  };

  const international = region === 1;

  const handleTabChange = (newRegion) => {
    setLoading(true);
    setTimeout(() => {
      setRegion(newRegion);

      if (newRegion === 1) {
        searchParams.set("international", true);
      } else {
        searchParams.delete("international");
      }
      setSearchParams(searchParams);
      setLoading(false);
    }, 300);
  };

  const { faqItems, guideItemList } = REGION_DATA[region];

  return (
    <PageLoading loading={loading}>
      <div className="pl-10 md:pr-0 mr-20">
        <Breadcrumb path={breadcrumbPath} />
      </div>

      <div className="mt-8 items-center flex flex-col">
        <SdfSegmentedControl
          selectedIndex={region}
          items={ITEMS}
          className="mb-12"
          onSdfChange={(event) => {
            handleTabChange(event.detail);
          }}
        ></SdfSegmentedControl>

        <>
          <MarketplaceSection {...{ region }} />
          <ClientSection {...{ region }} />
          <PartnerSection {...{ region }} />
          <div className="overflow-hidden flex flex-col items-center py-20">
            <AvailableGuides {...{ guideItemList, international }} />
          </div>
          <div className="min-w-full bg-action-default-50 relative">
            <FAQs {...{ faqItems }} />
          </div>
        </>
      </div>
    </PageLoading>
  );
}
